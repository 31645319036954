<template>
    <div class="small">
        <app-statistics-line-chart ref="linechart" v-if="datacollection !== null" :chart-data="datacollection" :options="options"></app-statistics-line-chart>
        <button type="button" class="button is-primary" v-on:click="featureAll">All</button>
        <button type="button" class="button is-primary" v-on:click="featureClicks">Clicks</button>
        <button type="button" class="button is-primary" v-on:click="featureIcons">Icons</button>
        <button type="button" class="button is-primary" v-on:click="featureAppViews">Views</button>
    </div>
</template>

<script>
import AppStatisticsLineChart from "./AppStatisticsLineChart";

export default {
    name: "AppStatisticsClicks",
    components: {
        AppStatisticsLineChart,
    },
    props: [
        'appId',
    ],
    data () {
        return {
            datacollection: null,
            options: null,
            featureIndexSubstract: 0,
        }
    },
    mounted() {
        this.loadData();

        if(!this.$featureTransactions) {
            this.featureIndexSubstract = 1;
        }

        this.options = {
            tooltips: {
                mode: 'index'
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: 'day'
                    }
                }]
            }
        }
    },
    methods: {
        featureAll() {
            this.datacollection.datasets.forEach(dataset => {
                dataset.hidden = false;
            });

            this.$refs.linechart.updateRender();
        },
        featureClicks() {
            this.deactivateAllDatasets();

            this.datacollection.datasets[0].hidden = false; // Clicks - Incoming
            this.datacollection.datasets[1].hidden = false; // Clicks - Outgoing
            if(this.$featureTransactions) {
                this.datacollection.datasets[2].hidden = false; // Clicks - Points
            }

            this.$refs.linechart.updateRender();
        },
        featureIcons() {
            this.deactivateAllDatasets();

            this.datacollection.datasets[(3-this.featureIndexSubstract)].hidden = false; // Icons - Displayed
            this.datacollection.datasets[(4-this.featureIndexSubstract)].hidden = false; // Icons - Views

            this.$refs.linechart.updateRender();
        },
        featureAppViews() {
            this.deactivateAllDatasets();

            this.datacollection.datasets[(5-this.featureIndexSubstract)].hidden = false; // Clicks - Incoming
            this.datacollection.datasets[(6-this.featureIndexSubstract)].hidden = false; // Clicks - Outgoing
            this.datacollection.datasets[(7-this.featureIndexSubstract)].hidden = false; // Clicks - Points

            this.$refs.linechart.updateRender();
        },
        deactivateAllDatasets() {
            this.datacollection.datasets.forEach(dataset => {
                dataset.hidden = true;
            });
        },
        loadData() {
            axios.get('/app/statistics/' + this.appId + '/clicks')
            .then(result => {
                this.fillData(result.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        fillData (data) {
            this.datacollection = {
                labels: [this.getRandomInt(), this.getRandomInt()],
                datasets: [
                    {
                        label: 'Clicks - Incoming',
                        borderColor: '#00FF00',
                        fill: false,
                        lineTension: 0.0,
                        data: data.incoming,
                    },
                    {
                        label: 'Clicks - Outgoing',
                        borderColor: '#CCCC00',
                        fill: false,
                        lineTension: 0.0,
                        data: data.outgoing,
                    },

                    {
                        label: 'Icons - Displayed',
                        borderColor: '#4A235A',
                        fill: false,
                        lineTension: 0.0,
                        data: data.icons_displayed,
                        hidden: true,
                    },
                    {
                        label: 'Icons - Views',
                        borderColor: '#78281F',
                        fill: false,
                        lineTension: 0.0,
                        data: data.icon_views,
                        hidden: true,
                    },

                    {
                        label: 'Views - Overall',
                        borderColor: '#FFFFFF',
                        fill: false,
                        lineTension: 0.0,
                        data: data.views_overall,
                        hidden: true,
                    },
                    {
                        label: 'Views - New',
                        borderColor: '#28B463',
                        fill: false,
                        lineTension: 0.0,
                        data: data.views_new,
                        hidden: true,
                    },
                    {
                        label: 'Views - Old',
                        borderColor: '#2E86C1',
                        fill: false,
                        lineTension: 0.0,
                        data: data.views_old,
                        hidden: true,
                    },
                ],
            };

            if(this.$featureTransactions) {
                this.datacollection.datasets.splice(2, 0, {
                    label: 'Clicks - Points',
                    borderColor: 'black',
                    fill: false,
                    lineTension: 0.0,
                    data: data.points,
                });
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        }
    }
}
</script>

<style scoped>
    .small {
        max-width: 600px;
        margin:  150px auto;
    }
</style>
