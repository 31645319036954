<template>
    <div>
        <div class="circle-wrapper circle-wrapper-color">
            <svg viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" class="circle-progress-wrapper">
                <circle cx="75" cy="75" r="67.5" class="circle-progress" stroke="#e59b30"></circle>
            </svg>
            <div class="inside-circle inside-circle-color has-black-text">
                <div class="display-flex width-100p height-100p is-vertical-center inside-circle-numbers">
                    <div>
                        <p>0</p>
                        <p class="is-size-5" style="color: #e59b30;">$</p>
                    </div>
                </div>
            </div>
        </div>
        <article class="message circle-box-margin-top">
            <div class="message-body message-border-color-4">
                <div class="is-size-4-custom has-text-centered add-horizontal-center"
                     :class="{ 'min-text-height box-margin-top': !this.isMobile, 'min-text-height-mobile box-margin-top-mobile': this.isMobile }">
                    <p>Usage</p>
                    <p>is totally</p>
                    <p class="is-size-3-custom"><strong>for free</strong>!</p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Startseite-3',
        props: [
            'isMobile',
        ],
    }
</script>
