<template>
<section>
    <h3 class="subtitle">Page Views</h3>

    <b-table
        v-if="data.length > 0"
        :data="data"
        paginated
        per-page="10"
    >
        <b-table-column
            field="views"
            label="Views"
            sortable
            numeric
            v-slot="props"
        >
            {{ props.row.views }}
        </b-table-column>

        <b-table-column
            field="path"
            sortable
            label="Path"
            v-slot="props"
        >
            {{ props.row.path }}
        </b-table-column>

        <b-table-column
            field="method"
            sortable
            label="Method"
            v-slot="props"
        >
            {{ props.row.method }}
        </b-table-column>

        <b-table-column
            field="last_update"
            sortable
            label="Last Update"
            v-slot="props"
        >
            {{ props.row.last_update }}
        </b-table-column>
    </b-table>
</section>
</template>

<script>
export default {
    name: "PageViews",
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        this.loadPageViews();
        setInterval(this.loadPageViews, 10000);
    },
    methods: {
        loadPageViews() {
            axios.get('/statistics/page-views')
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }
}
</script>

<style scoped>

</style>
