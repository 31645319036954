<template>
<section>
    <div class="columns display-flex align-right">
        <div class="column is-2 display-flex align-right">
            <div class="notification is-primary margin-navbar notification-custom" v-if="!mailSent && !error">
                <div>
                    Your e-mail-address is not verified. To use all features, complete the verification.
                </div>

                <br>

                <button
                    class="button is-dark"
                    :class="{ 'is-loading': sendingInProgress }"
                    :disabled="sendingInProgress"
                    type="button"
                    v-on:click="sendVerificationMail"
                >
                    Send Verification Link
                </button>
            </div>

            <div class="notification is-success margin-navbar notification-custom" v-if="mailSent && !error">
                E-mail has been sent. Look into your inbox!
            </div>

            <div class="notification is-danger margin-navbar notification-custom" v-if="error">
                E-mail has been sent. Look into your inbox!
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "VerificationNotification",
    data() {
        return {
            sendingInProgress: false,
            mailSent: false,
            error: false,
        }
    },
    methods: {
        sendVerificationMail() {
            this.sendingInProgress = true;

            axios.post('/user/send-verification-mail')
            .then(response => {
                this.mailSent = true;
            })
            .catch(error => {
                this.error = true;
            });
        }
    }
}
</script>

<style scoped>

</style>
