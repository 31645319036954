<template>
    <section class="hero is-medium">
        <div class="hero-body">
            <div class="columns">
                <div class="column is-12">
                    <h1 class="title has-text-centered">Dashboard</h1>
                    <hr>
                    <div class="columns">
                        <div class="column is-6">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-left"><strong>User Data</strong></h2>
                                    <user-overview
                                        ref="userOverview"
                                    ></user-overview>
                                </div>
                            </article>
                        </div>
                        <div class="column is-6">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-left"><strong>Add a new App</strong></h2>
                                    <add-app-component
                                        v-on:reload-app-list="reloadAppList"
                                    ></add-app-component>
                                </div>
                            </article>
                        </div>
                    </div>
                    <hr>
                    <app-list-component
                        ref="appList"
                    ></app-list-component>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import UserOverview from "./UserOverview";
import AddAppComponent from "./AddAppComponent";
import AppListComponent from "./AppListComponent";

export default {
    components: {
        UserOverview,
        AddAppComponent,
        AppListComponent,
    },
    name: "IndexPortal",
    methods: {
        reloadAppList() {
            this.$refs.appList.getAll();
            this.$refs.userOverview.getUser();
        }
    }
}
</script>

<style scoped>

</style>
