<template>
<section>
    <transactions-list></transactions-list>
</section>
</template>

<script>
import TransactionsList from "./TransactionsList";

export default {
    components: {
        TransactionsList
    },
    name: "IndexTransactions"
}
</script>

<style scoped>

</style>
