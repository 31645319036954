<template>
    <section>
        <table class="table is-fullwidth">
            <tr>
                <td>Username</td>
                <td>{{ name }}</td>
            </tr>
            <tr v-if="this.$featureTransactions">
                <td>Points</td>
                <td>{{ points }}</td>
            </tr>
            <tr>
                <td>Apps</td>
                <td>{{ currentApps }} / {{ maxApps }}</td>
            </tr>
        </table>
    </section>
</template>

<script>
export default {
    name: "UserOverview",
    data() {
        return {
            name: null,
            points: 0,
            currentApps: 0,
            maxApps: 0
        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser() {
            axios.get('/user')
            .then(response => {
                let user = response.data;

                this.name = user.name;
                this.points = user.points;
                this.currentApps = user.current_apps;
                this.maxApps = user.max_apps;

                if(user.type === 0) {
                    this.maxApps = '∞';
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        }
    }
}
</script>

<style scoped>

</style>
