<template>
    <vue-dropzone
        ref="iconDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-success="triggerAppIconListReload"
    ></vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    name: "AppIconUpload",
    components: {
        vueDropzone: vue2Dropzone
    },
    props: [
        'appId',
    ],
    data: function () {
        return {
            dropzoneOptions: {
                url: '/api/v1/app/upload-icon',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                maxFilesize: 2,
                headers: { "Authorization": "Bearer " + this.$cookies.get('auth') },
                maxFiles: 1,
                acceptedFiles: 'image/jpeg, image/gif, image/png',
                params: {
                    app_id: this.appId,
                },
                dictDefaultMessage: 'Drop icon here to upload'
            }
        }
    },
    methods: {
        triggerAppIconListReload() {
            this.$emit('reload-app-icons-list');
            this.$refs.iconDropzone.removeAllFiles();
        }
    }
}
</script>

<style scoped>
    .dropzone {
        margin-left: auto;
        margin-right: auto;
        background-color: #0a0a0a;
    }

    .vue-dropzone {
        border: 3px solid #009e86;
        color: #dbdbdb;
        font-size: 1.5rem !important;
    }
</style>
