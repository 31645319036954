<template>
    <div>
        <div class="circle-wrapper circle-wrapper-color" data-aos="circle-progress-animate-1">
            <svg viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" class="circle-progress-wrapper">
                <circle cx="75" cy="75" r="67.5" class="circle-progress" stroke="#009e86"></circle>
            </svg>
            <div class="inside-circle inside-circle-color has-black-text">
                <div class="display-flex width-100p height-100p is-vertical-center inside-circle-numbers inside-circle-numbers-animate-1">
                    <div v-for="(n, i) in 51">
                        <p>+{{ i*200 }}</p>
                        <p class="is-size-5" style="color: #009e86;">downloads</p>
                    </div>
                </div>
            </div>
        </div>
        <article class="message circle-box-margin-top">
            <div class="message-body message-border-color-3">
                <div class="is-size-4-custom has-text-centered add-horizontal-center"
                   :class="{ 'min-text-height box-margin-top': !this.isMobile, 'min-text-height-mobile box-margin-top-mobile': this.isMobile }">
                    <p>Up to</p>
                    <p class="is-size-3-custom"><strong>10000</strong></p>
                    <p>new downloads</p>
                    <p>each day!</p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Startseite-1',
        props: [
            'isMobile',
        ],
    }
</script>
