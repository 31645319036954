<template>
<section>
    <img :src="iconUrl" style="width: 175px; height: auto;">
</section>
</template>

<script>
export default {
    name: "AppIconActive",
    props: [
        'appId'
    ],
    data() {
        return {
            iconUrl: '/images/icon_placeholder.png',
            noIcon: false,
        }
    },
    mounted() {
        this.loadActiveAppIcon();
    },
    methods: {
        loadActiveAppIcon() {
            axios.get('/app/' + this.appId + '/current-icon')
            .then(response => {
                this.iconUrl = response.data.icon_url;
            })
            .catch(error => {
                console.log(error.response);
                this.noIcon = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
