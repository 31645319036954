<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white is-size-title">
                            Boost Your App
                        </h1>
                        <p></p>
                        <h2 class="subtitle has-text-white is-size-subtitle">
                            <strong>
                                More downloads.
                                <br>
                                For free.
                            </strong>
                        </h2>
                        <br>
                        <div class="has-text-centered">
                            <a @click="$moveToAnchor('how-to-integrate')"
                                 class="button is-primary is-size-5-custom">
                                Get started
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white is-size-title">
                            Boost Your App
                        </h1>
                        <p></p>
                        <h2 class="subtitle has-text-white is-size-subtitle">
                            <strong>
                                More downloads.
                                <br>
                                For free.
                            </strong>
                        </h2>
                        <br>
                        <div class="has-text-centered">
                            <a @click="$moveToAnchor('how-to-integrate')"
                                 class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                Get started
                            </a>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="why-to-use-anchor" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        No matter how many downloads your apps generate, with <strong>Boost Your App</strong> you will increase the number even more!</h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Use <strong>Boost Your App</strong> and get the most out of your apps! Whether it's an established app or just recently on the market, with <strong>Boost Your App</strong> the visibility and the number of downloads will increase significantly.
                    </p>
                    <br>
                    <carousel
                        carousel="Startseite-1"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <div class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <p>What are you waiting for?</p>
                        <a @click="$moveToAnchor('how-to-integrate')">Get started now! &#8811;</a>
                    </div>
                </div>
            </div>
        </section>
        <section id="how-it-works-anchor" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        A huge network of app developers
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        The power of <strong>Boost Your App</strong> lies in the network. The more app developers the framework use, the more powerful it is.
                    </p>
                    <br>
                    <div class="columns is-centered is-vcentered">
                        <div class="column is-6">
                            <figure class="image">
                                <img src="/images/framework.svg" alt="Framework">
                            </figure>
                        </div>
                        <div class="column is-6">
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                <p class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>How does it work?</strong>
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    When you integrate the framework into your app, your app will have a <strong>new first activity</strong>.
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    Up to <strong>4 apps are displayed on this activity</strong>. These apps are from other <strong>Boost Your App</strong> users and refer to the store page of the app.
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    If someone finds an app interesting and <strong>clicks on it</strong>, he/she <strong>goes straight to the store</strong> and can try the app right away!
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    And <strong>in apps of other users your apps will be displayed</strong>!
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    That's all! 🎉 You could also say that <strong>apps support apps</strong> or <strong>app developers support each other</strong>.
                                </p>
                                <br>
                                <p class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>What else?</strong>
                                </p>
                                <br>
                                <p class="is-size-5-custom">
                                    Of course, we check every app and every icon for strict <a @click="$moveToAnchor('guidelines')">Guidelines</a> before they are approved and displayed in other apps.
                                </p>
                                <br>
                                <article class="message is-success">
                                    <div class="message-body">
                                        <p class="is-size-5-custom">
                                            The framework complies with the <a href="https://play.google.com/about/developer-content-policy/" target="_blank" rel="nofollow" class="text-blue">Google Play Policies</a>.
                                        </p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="columns">
                        <div class="column is-12">
                            <p class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <strong>Your App - Your Control</strong>
                            </p>
                            <br>
                            <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                It is your app that includes the <strong>Boost Your App</strong> framework and therefore you have the control about your app and several options of how you can use the <strong>Boost Your App</strong> framework. Here are some examples:
                            </p>
                            <div class="content is-size-5-custom">
                                <ul>
                                    <li>Use the <strong>Boost Your App</strong> framework only for your own apps (private-mode)</li>
                                    <li>If you have multiple apps and some of them are performing well, you can use the <strong>Boost Your App</strong> framework to boost a new app</li>
                                    <li>For each app you can select one of your own apps for a specific slot in the <strong>Boost Your App</strong> framework</li>
                                    <li>You can disable the <strong>Boost Your App</strong> framework for each app or only disable some slots</li>
                                    <li>Use several charts and statistics to evaluate the behavior of your apps and the <strong>Boost Your App</strong> framework</li>
                                </ul>
                            </div>
                            <br>
                            <div class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <p>What are you waiting for?</p>
                                <a @click="$moveToAnchor('how-to-integrate')">Get started now! &#8811;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="how-to-integrate-anchor" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Integrate in only 2 minutes
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        We love easy things and therefore it's really easy to implement the <strong>Boost Your App</strong> framework. You can choose between Gradle and Maven.
                    </p>
                    <br>
                    <article class="message is-warning">
                        <div class="message-body">
                            We are working hard on <strong>Boost Your App</strong> so you will get out the most of it. But unfortunatly it is currently <strong>only available for Android</strong>.
                        </div>
                    </article>
                    <br>
                    <div class="tabs is-toggle margin-bottom-unset" :class="{ 'is-centered': !this.isMobile || this.isTablet }">
                        <ul>
                            <li :class="{ 'is-active': this.gradle }" @click="gradle = !gradle">
                                <a>
                                    <span>Gradle</span>
                                </a>
                            </li>
                            <li :class="{ 'is-active': !this.gradle }" @click="gradle = !gradle">
                                <a>
                                    <span>Maven</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <br>
                    <div v-if="this.gradle" class="has-text-left">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            Add the <strong>Boost Your App</strong> repository to your <span class="text-primary">top build.gradle</span> file.
                        </p>
                        <br>
                        <div class="box code-block-custom has-text-left is-size-6"
                             :class="{ 'code-box-border': this.boxBorder[0] }"
                             @mouseenter="mouseBox(0, true)"
                             @mouseleave="mouseBox(0, false)">
                            <button v-if="this.boxButtonIsVisible[0]" ref="buttonbox0" class="button is-primary pos-absolute right-125rem"
                                    @click="copyText(0)"
                                    @mouseenter="mouseBoxButton(0, true)"
                                    @mouseleave="mouseBoxButton(0, false)">
                                Copy
                            </button>
                            <div ref="box0" class="code-block-custom-padding">
                                <span>allprojects {</span>
                                <br>
                                <span>&nbsp;&nbsp;repositories {</span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;...</span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;maven { url <span class="text-color-1">'https://maven.boost-your-app.com/artifactory/boost-your-app-framework'</span> }</span>
                                <br>
                                <span>&nbsp;&nbsp;}</span>
                                <br>
                                <span>}</span>
                            </div>
                        </div>
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            Add the dependency to your <span class="text-primary">app's build.gradle</span> file.
                        </p>
                        <br>
                        <div class="box code-block-custom has-text-left is-size-6"
                             :class="{ 'code-box-border': this.boxBorder[1] }"
                             @mouseenter="mouseBox(1, true)"
                             @mouseleave="mouseBox(1, false)">
                            <button v-if="this.boxButtonIsVisible[1]" ref="buttonbox1" class="button is-primary pos-absolute right-125rem"
                                    @click="copyText(1)"
                                    @mouseenter="mouseBoxButton(1, true)"
                                    @mouseleave="mouseBoxButton(1, false)">
                                Copy
                            </button>
                            <div ref="box1" class="code-block-custom-padding">
                                <span>dependencies {</span>
                                <br>
                                <span>&nbsp;&nbsp;...</span>
                                <br>
                                <span>&nbsp;&nbsp;implementation <span class="text-color-1">'com.boost-your-app.BoostYourAppFramework:BoostYourAppFramework:0.23'</span></span>
                                <br>
                                <span>}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="has-text-left">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            Add the <strong>Boost Your App</strong> repository to your <span class="text-primary">pom.xml</span>.
                        </p>
                        <br>
                        <div class="box code-block-custom has-text-left is-size-6"
                             :class="{ 'code-box-border': this.boxBorder[2] }"
                             @mouseenter="mouseBox(2, true)"
                             @mouseleave="mouseBox(2, false)">
                            <button v-if="this.boxButtonIsVisible[2]" ref="buttonbox2" class="button is-primary pos-absolute right-125rem"
                                    @click="copyText(2)"
                                    @mouseenter="mouseBoxButton(2, true)"
                                    @mouseleave="mouseBoxButton(2, false)">
                                Copy
                            </button>
                            <div ref="box2" class="code-block-custom-padding">
                                <span>&#60;<span class="text-color-1">repository</span>&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&#60;<span class="text-color-1">id</span>&#62;maven.boost-your-app.com&#60;/<span class="text-color-1">id</span>&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&#60;<span class="text-color-1">url</span>&#62;https://maven.boost-your-app.com/artifactory/boost-your-app-framework&#60;/<span class="text-color-1">url</span>&#62;</span>
                                <br>
                                <span>&#60;/<span class="text-color-1">repository</span>&#62;</span>
                            </div>
                        </div>
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">Add the dependency to your <span class="text-primary">pom.xml</span>.</p>
                        <br>
                        <div class="box code-block-custom has-text-left is-size-6"
                             :class="{ 'code-box-border': this.boxBorder[3] }"
                             @mouseenter="mouseBox(3, true)"
                             @mouseleave="mouseBox(3, false)">
                            <button v-if="this.boxButtonIsVisible[3]" ref="buttonbox3" class="button is-primary pos-absolute right-125rem"
                                    @click="copyText(3)"
                                    @mouseenter="mouseBoxButton(3, true)"
                                    @mouseleave="mouseBoxButton(3, false)">
                                Copy
                            </button>
                            <div ref="box3" class="code-block-custom-padding">
                                <span>&#60;<span class="text-color-1">dependency</span>&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&#60;<span class="text-color-1">groupId</span>&#62;com.boost-your-app.BoostYourAppFramework&#60;/<span class="text-color-1">groupId</span>&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&#60;<span class="text-color-1">artifactId</span>&#62;BoostYourAppFramework&#60;/<span class="text-color-1">artifactId</span>&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&#60;<span class="text-color-1">version</span>&#62;0.23&#60;/<span class="text-color-1">version</span>&#62;</span>
                                <br>
                                <span>&#60;/<span class="text-color-1">dependency</span>&#62;</span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="has-text-left">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            Add the meta data to your <span class="text-primary">AndroidManifest.xml</span> file.
                        </p>
                        <br>
                        <div class="box code-block-custom has-text-left is-size-6"
                             :class="{ 'code-box-border': this.boxBorder[4] }"
                             @mouseenter="mouseBox(4, true)"
                             @mouseleave="mouseBox(4, false)">
                            <button v-if="this.boxButtonIsVisible[4]" ref="buttonbox4" class="button is-primary pos-absolute right-125rem"
                                    @click="copyText(4)"
                                    @mouseenter="mouseBoxButton(4, true)"
                                    @mouseleave="mouseBoxButton(4, false)">
                                Copy
                            </button>
                            <div ref="box4" class="code-block-custom-padding">
                                <span class="text-color-2">&#60;application</span>
                                <br>
                                <span>&nbsp;&nbsp;...<span class="text-color-2">&#62;</span></span>
                                <br>
                                <br>
                                <span class="text-color-3">&nbsp;&nbsp;&#60;!-- The <i>APP_API_KEY</i> you will receive after adding an app in your account. --&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;<span class="text-color-2">&#60;meta-data</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="com.boostyourapp.APP_API_KEY"</span> <span class="text-color-4">android</span>:value<span class="text-color-1">="<span class="text-color-5">APP_API_KEY</span>"</span><span class="text-color-2">/&#62;</span></span>
                                <br>
                                <span class="text-color-3">&nbsp;&nbsp;&#60;!-- The <i>APP_PACKAGE_NAME</i> is the package name of your app. --&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;<span class="text-color-2">&#60;meta-data</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="com.boostyourapp.APP_PACKAGE"</span> <span class="text-color-4">android</span>:value<span class="text-color-1">="<span class="text-color-5">APP_PACKAGE_NAME</span>"</span><span class="text-color-2">/&#62;</span></span>
                                <br>
                                <span class="text-color-3">&nbsp;&nbsp;&#60;!-- The <i>APP_START_ACTIVITY</i> is your first activity class for example MainActivity. --&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;<span class="text-color-2">&#60;meta-data</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="com.boostyourapp.APP_START_ACTIVITY"</span> <span class="text-color-4">android</span>:value<span class="text-color-1">="<span class="text-color-5">START_ACTIVITY_NAME</span>"</span><span class="text-color-2">/&#62;</span></span>
                                <br>
                                <br>
                                <span class="text-color-3">&nbsp;&nbsp;&#60;!-- The new start activity of your app. The framework will link to your activity class with the name <i>START_ACTIVITY_NAME</i>. --&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;<span class="text-color-2">&#60;activity</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="com.boostyourapp.boostyourappframework.Framework"</span><span class="text-color-2">&#62;</span></span>
                                <br>
                                <span class="text-color-2">&nbsp;&nbsp;&nbsp;&nbsp;&#60;intent-filter&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-color-2">&#60;action</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="android.intent.action.MAIN"</span><span class="text-color-2">/&#62;</span></span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-color-2">&#60;action</span> <span class="text-color-4">android</span>:name<span class="text-color-1">="android.intent.category.LAUNCHER"</span><span class="text-color-2">/&#62;</span></span>
                                <br>
                                <span class="text-color-2">&nbsp;&nbsp;&nbsp;&nbsp;&#60;/intent-filter&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;...</span>
                                <br>
                                <span class="text-color-2">&nbsp;&nbsp;&#60;/activity&#62;</span>
                                <br>
                                <br>
                                <span class="text-color-3">&nbsp;&nbsp;&#60;!-- Your other activities. --&#62;</span>
                                <br>
                                <span class="text-color-2">&nbsp;&nbsp;&#60;activity&#62;</span>
                                <br>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;...</span>
                                <br>
                                <span class="text-color-2">&nbsp;&nbsp;&#60;/activity&#62;</span>
                                <br>
                                <br>
                                <span class="text-color-2">&#60;/application&#62;</span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        Congratulations! 🎉  That's all needed! Start now configuring your app on your <a href="/login">Dashboard &#8811;</a>.
                    </p>
                </div>
            </div>
        </section>
        <section id="pricing-anchor" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Pricing
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        There are <strong>no costs</strong>. The service of <strong>Boost Your App</strong> is <strong>totally free</strong> for you.
                    </p>
                </div>
            </div>
        </section>
        <section id="guidelines-anchor" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Guidelines
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        To ensure quality of <strong>Boost Your App</strong> and to build a safe environment, we established guidelines that have to be followed by our users. The target of the guidelines
                        are primarily the icons that can be uploaded to our system and that are displayed in apps of other users. Generally speaking, the icon and also your app must not
                        violate the <a href="https://play.google.com/about/developer-content-policy/" target="_blank">Google Play Policies</a>. The following section contains a list of things we do not allow to be displayed on an icon.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Nudity and Sexual Content</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        It is not allowed to display nudity or sexual content on icons.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Excessive Violence</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Icons may not show any excessive violence that could disturb app users.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Hate Speech</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Hate speech of any kind is not allowed to be shown on an icon.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Display of Drug Usage</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Displaying or promoting drug usage is not allowed.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Copyrighted Material</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        It is not allowed to use any material that is under copyright, except it is your own work.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>False Claims</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        It is not allowed to make false claims on an icon.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Miscellaneous</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        We provide ourselves the right to deny any icon for any reason. Normally, we do not do this but there may be content displayed on an icon
                        that is harmful for the user's experience and does not fit into our other guidelines. If such a case arises, we will open a new
                        guideline category.
                    </p>

                    <br>

                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>Disclaimer</strong>
                    </p>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        Rules for icons may change at any time and without further notification.
                    </p>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="white-background"></WaveFooter>
        <footer-element
            :is-mobile="this.isMobile"
            :is-tablet="this.isTablet">
        </footer-element>
    </section>
</template>

<script>
    import WaveFooter from './components/wave-footer-bottom';
    import WaveBottom from './components/wave-bottom';
    import Particles from './components/particles-stars';

    export default {
        name: "App",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                gradle: true,
                boxButtonIsVisible: [ false, false, false, false, false ],
                boxBorder: [ false, false, false, false, false ],
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
        },
        methods: {
            changeButtonBoxText(index, text) {
                const buttonbox = this.$refs[`buttonbox${index}`];
                buttonbox.innerText = text;
            },
            mouseBox(index, state) {
                this.$set(this.boxButtonIsVisible, index, state);
            },
            mouseBoxButton(index, state) {
                this.$set(this.boxBorder, index, state);

                if (!state) {
                    this.changeButtonBoxText(index, "Copy");
                }
            },
            copyText(index) {
                this.changeButtonBoxText(index, "Copied");

                const el = this.$refs[`box${index}`];

                if (document.body.createTextRange) {
                    const range = document.body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                    document.execCommand('copy');
                } else if (window.getSelection) {
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.selectNodeContents(el);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('copy');
                }

                if (window.getSelection) {
                    window.getSelection().removeAllRanges();
                }
                else if (document.selection) {
                    document.selection.empty();
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
