<template>
    <section>
        <h3 class="subtitle">App Info Review</h3>
        <p>Apps in review: {{ apps }}</p>

        <b-table
            v-if="data.length > 0"
            :data="data"
            paginated
            per-page="10"
        >
            <b-table-column
                field="app_name"
                label="App"
                sortable
                v-slot="props"
            >
                {{ props.row.app_name }}
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
export default {
    name: "AppReviewList",
    data() {
        return {
            apps: 0,
            data: [],
        }
    },
    mounted() {
        this.loadAppInfoReview();
    },
    methods: {
        loadAppInfoReview() {
            axios.get('/admin/app-info-review')
                .then(response => {
                    this.data = response.data.data;
                    this.apps = response.data.amount;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }
}
</script>

<style scoped>

</style>
