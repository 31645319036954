<template>
    <section>
        <b-table
            v-if="data.length > 0"
            :data="data"
            paginated
            per-page="15"
        >
            <b-table-column
                field="id"
                label="ID"
                sortable
                v-slot="props"
            >
                {{ props.row.id }}
            </b-table-column>

            <b-table-column
                field="type"
                label="Type"
                sortable
                v-slot="props"
            >
                <span class="tag" :class="props.row.tag.class">{{ props.row.tag.content }}</span>
            </b-table-column>

            <b-table-column
                field="app"
                label="App"
                sortable
                v-slot="props"
            >
                {{ props.row.app_name }}
            </b-table-column>

            <b-table-column
                field="points"
                label="Points"
                sortable
                v-slot="props"
            >
                <div v-if="props.row.points <= 0" style="display: inline-block;">
                    {{ props.row.points }}
                </div>
                <div v-else style="display: inline-block; color: green;">
                    {{ props.row.points }}
                </div>
            </b-table-column>

            <b-table-column
                field="date"
                label="Date"
                sortable
                v-slot="props"
            >
                {{ props.row.date }}
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
export default {
    name: "TransactionsList",
    data() {
        return {
            data: []
        }
    },
    mounted() {
        this.loadTransactions();
    },
    methods: {
        loadTransactions() {
            axios.get('/transactions')
            .then(result => {
                this.data = result.data;
            })
            .catch(error => {
                console.log(error.response);
            })
        }
    }
}
</script>

<style scoped>

</style>
