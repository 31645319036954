<template>
<section>
    <h3 class="subtitle">
        Graph

        <app-statistics-clicks :app-id="appId"></app-statistics-clicks>
    </h3>
</section>
</template>

<script>
import AppStatisticsClicks from "./AppStatisticsClicks";

export default {
    name: "IndexStatistics",
    components: {
        AppStatisticsClicks
    },
    props: [
        'appId',
    ]
}
</script>

<style scoped>

</style>
