<template>
<section>
    Statistics

    <page-views></page-views>
    <hr />
    <visitors></visitors>
</section>
</template>

<script>
import PageViews from "./PageViews";
import Visitors from "./Visitors";

export default {
    components: {
        PageViews,
        Visitors,
    },
    name: "IndexStatistics",
}
</script>

<style scoped>

</style>
