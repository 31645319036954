<template>
    <header>
        <nav id="navbar-wrapper" class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a @click="$moveToAnchor('start')" class="navbar-logo navbar-item navbar-router-link" :class="{ 'display-none': this.navbarDesktop }">
                    <img src="/images/bya.svg" alt="BYA" width="81.506" height="30">
                </a>

                <a id="navbar-burger" role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBYA"
                   @click="this.toggleMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar-menu" class="navbar-menu">
                <ul class="navbar-start navbar-center-menu">
                    <li class="navbar-logo navbar-item navbar-item-center navbar-router-link" :class="{ 'display-none': !this.navbarDesktop }">
                        <a @click="$moveToAnchor('start')">
                            <img src="/images/bya.svg" alt="BYA" width="81.506" height="30" class="is-vertical-center">
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('start')">
                            <span class="is-size-5">Home</span>
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('why-to-use')">
                            <span class="is-size-5">Why To Use</span>
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('how-it-works')">
                            <span class="is-size-5">How It Works</span>
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('how-to-integrate')">
                            <span class="is-size-5">How to Integrate</span>
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('pricing')">
                            <span class="is-size-5">Pricing</span>
                        </a>
                    </li>

                    <li class="navbar-item-center navbar-router-link">
                        <a class="navbar-item navbar-item-center width-100p" @click="$moveToAnchor('guidelines')">
                            <span class="is-size-5">Guidelines</span>
                        </a>
                    </li>

                    <li v-if="!this.user" class="navbar-item-center navbar-router-link">
                        <a href="/login" class="navbar-item navbar-item-center width-100p">
                            <strong class="text-primary is-size-5">Login</strong>
                        </a>
                    </li>

                    <li v-if="!this.user" class="navbar-item-center navbar-router-link">
                        <a href="/register" class="navbar-item navbar-item-center width-100p">
                            <strong class="text-primary is-size-5">Register</strong>
                        </a>
                    </li>

                    <li v-if="this.user" class="navbar-item-center navbar-router-link navbar-item has-dropdown navbar-item-center-dropdown" :class="{ 'is-active': this.showServices }"
                        @mouseover="this.showAllServices" @mouseout="this.hideAllServices">
                        <a href="/portal" id="navbar-leistungen" class="navbar-item height-100p">
                            <strong class="text-primary is-size-5">Dashboard</strong>
                        </a>

                        <ul class="navbar-dropdown navbar-item-center-dropdown navbar-dropdown-no-padding navbar-dropdown-background" :class="{ 'is-boxed': !this.isMobileComputed }">
                            <li v-if="this.$featureTransactions" class="navbar-router-link">
                                <a href="/portal/transactions" class="navbar-item">
                                    <span class="is-size-6">Transactions</span>
                                </a>
                            </li>
                            <li v-if="this.user.type === 0" class="navbar-router-link">
                                <a href="/portal/admin" class="navbar-item">
                                    <span class="is-size-6">Admin</span>
                                </a>
                            </li>
                            <li v-if="this.user.type === 0" class="navbar-router-link">
                                <a href="/portal/statistics" class="navbar-item">
                                    <span class="is-size-6">Statistics</span>
                                </a>
                            </li>
                            <li v-if="this.user.type <= 5" class="navbar-router-link">
                                <a href="/portal/review" class="navbar-item">
                                    <span class="is-size-6">Review</span>
                                </a>
                            </li>
                            <li class="navbar-router-link">
                                <a href="/logout" class="navbar-item" onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                                    <span class="is-size-6">Logout</span>
                                </a>
                                <form id="logout-form" action="/logout" method="POST" class="d-none">
                                    <input type="hidden" name="_token" :value="csrf">
                                </form>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    props: [
        'isMobile',
        'isTablet',
        'userData',
    ],
    data() {
        return {
            user: JSON.parse(this.userData),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            isActive: 'is-active',
            isMobileComputed: !!this.isMobile,
            isTabletComputed: !!this.isTablet,
            navbarDesktop: null,
            showServices: null,
            navbarItemHover: 'navbar-item-hover',
        }
    },
    methods: {
        getNavbarWrapper() {
            return document.getElementById('navbar-wrapper');
        },
        getMenu() {
            return document.getElementById('navbar-menu');
        },
        getBurger() {
            return document.getElementById('navbar-burger');
        },
        getNavbarLeistungen() {
            return document.getElementById('navbar-leistungen');
        },
        toggleMenu() {
            const menu = this.getMenu();
            const burger = this.getBurger();

            if (burger.classList.contains(this.isActive)) {
                burger.classList.remove(this.isActive);
            } else {
                burger.classList.add(this.isActive);
            }

            if (menu.classList.contains(this.isActive)) {
                menu.classList.remove(this.isActive);
            } else {
                menu.classList.add(this.isActive);
            }
        },
        showAllServices() {
            this.showServices = true;
            this.showOrHideNavbarLeistungenBackgroundAndTextColor(true);
        },
        hideAllServices() {
            this.showServices = null;
            this.showOrHideNavbarLeistungenBackgroundAndTextColor(false);
        },
        showOrHideNavbarLeistungenBackgroundAndTextColor(show) {
            const navbarLeistungen = this.getNavbarLeistungen();
            if (navbarLeistungen) {
                if (show) {
                    navbarLeistungen.classList.add(this.navbarItemHover);
                } else {
                    navbarLeistungen.classList.remove(this.navbarItemHover);
                }
            }
        },
        initListeners() {
            const navbarWrapper = this.getNavbarWrapper();
            const menu = this.getMenu();
            const burger = this.getBurger();

            if (menu && burger) {
                if (navbarWrapper) {
                    const links = navbarWrapper.querySelectorAll(".navbar-router-link");
                    links.forEach(link => {
                        link.addEventListener("click", function () {
                            burger.classList.remove("is-active");
                            menu.classList.remove("is-active");
                        });
                    });
                }
            }
        },
        customWindowResize() {
            this.displayLogo();
        },
        displayLogo() {
            const burger = this.getBurger();

            this.navbarDesktop = window.getComputedStyle(burger).display === 'none';
        }
    },
    created () {
        window.addEventListener('orientationchange', this.$windowOrientationEvent);
        window.addEventListener("resize", this.customWindowResize);

        if (!this.isMobileComputed && navigator.maxTouchPoints > 0 || this.isTabletComputed) {
            this.isMobileComputed = true;
            this.isTabletComputed = true;
            document.getElementById('font-css').href = window.location.protocol + "\/\/" + window.location.host + "/css/font-sizes-mobile.css";
        }
    },
    mounted() {
        this.displayLogo();
        this.initListeners();
    }
}
</script>

<style scoped>

</style>
