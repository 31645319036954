<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
                    <WaveBottom2 :is-mobile="this.isMobile"></WaveBottom2>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white">
                            Verify Your Email Address
                        </h1>
                        <article class="message is-danger auth-margin-error width-100p" v-if="this.sessionResent === '1'">
                            <div class="message-body has-text-left">
                                A fresh verification link has been sent to your email address.
                            </div>
                        </article>

                        <p class="is-size-5-custom">Before proceeding, please check your email for a verification link.</p>
                        <p class="is-size-5-custom">If you did not receive the email</p>
                        <form class="d-inline" method="POST" action="/verification/resend">
                            <input type="hidden" name="_token" :value="csrf">
                            <button type="submit" class="btn btn-link p-0 m-0 align-baseline">Click here to request another</button>.
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="columns is-vertical-center is-centered">
                            <div class="column is-3">
                                <h1 class="title has-text-white">
                                    Verify Your Email Address
                                </h1>
                                <article class="message is-danger auth-margin-error width-100p" v-if="this.sessionResent === '1'">
                                    <div class="message-body has-text-left">
                                        A fresh verification link has been sent to your email address.
                                    </div>
                                </article>

                                <p class="is-size-5-custom">Before proceeding, please check your email for a verification link.</p>
                                <p class="is-size-5-custom">If you did not receive the email</p>
                                <form class="d-inline" method="POST" action="/verification/resend">
                                    <input type="hidden" name="_token" :value="csrf">
                                    <button type="submit" class="btn btn-link p-0 m-0 align-baseline">Click here to request another</button>.
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom2 :is-mobile="this.isMobile"></WaveBottom2>
            </section>
        </div>
        <footer-element
            :is-mobile="this.isMobile"
            :is-tablet="this.isTablet">
        </footer-element>
    </section>
</template>

<script>
    import WaveBottom2 from '../Layout/components/wave-bottom-2';
    import Particles from '../Layout/components/particles-stars';

    export default {
        name: "Verify",
        props: [
            'isMobile',
            'isTablet',
            'sessionResent',
        ],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        components: {
            WaveBottom2,
            Particles,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
