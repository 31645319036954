<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-4 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white">
                            Please confirm your password before continuing.
                        </h1>
                        <form method="POST" action="/password/confirm">
                            <input type="hidden" name="_token" :value="csrf">

                            <div class="is-size-5-custom has-text-left"><strong>Password</strong></div>
                            <div class="control has-icons-left">
                                <input type="password"
                                       class="input form-control"
                                       :class="{ 'is-danger': this.errorPassword === '1' }"
                                       name="password"
                                       required
                                       autocomplete="new-password"
                                       placeholder="Your Password">
                                <span class="icon is-small is-left">
                                    <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'key' }"/>
                                </span>

                                <article class="message is-danger auth-margin-error width-100p" v-if="this.errorPassword === '1'">
                                    <div class="message-body has-text-left">
                                        {{ this.errorPasswordMessage }}
                                    </div>
                                </article>
                            </div>

                            <br>

                            <div class="control">
                                <button type="submit" class="is-size-5-custom button is-primary width-100p">
                                    Confirm Password
                                </button>
                            </div>

                            <br>

                            <div class="control">
                                <a class="is-size-5-custom button is-info width-100p" href="/password/reset">
                                    Forgot Your Password?
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="columns is-vertical-center is-centered">
                            <div class="column is-3">
                                <h1 class="title has-text-white">
                                    Please confirm your password before continuing.
                                </h1>
                                <form method="POST" action="/password/confirm">
                                    <input type="hidden" name="_token" :value="csrf">

                                    <div class="is-size-5-custom has-text-left"><strong>Password</strong></div>
                                    <div class="control has-icons-left">
                                        <input type="password"
                                               class="input form-control"
                                               :class="{ 'is-danger': this.errorPassword === '1' }"
                                               name="password"
                                               required
                                               autocomplete="new-password"
                                               placeholder="Your Password">
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'key' }"/>
                                        </span>

                                        <article class="message is-danger auth-margin-error width-100p" v-if="this.errorPassword === '1'">
                                            <div class="message-body has-text-left">
                                                {{ this.errorPasswordMessage }}
                                            </div>
                                        </article>
                                    </div>

                                    <br>

                                    <div class="control">
                                        <button type="submit" class="is-size-5-custom button is-primary width-100p">
                                            Confirm Password
                                        </button>
                                    </div>

                                    <br>

                                    <div class="control">
                                        <a class="is-size-5-custom button is-info width-100p" href="/password/reset">
                                            Forgot Your Password?
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom2 :is-mobile="this.isMobile"></WaveBottom2>
            </section>
        </div>
        <footer-element
            :is-mobile="this.isMobile"
            :is-tablet="this.isTablet">
        </footer-element>
    </section>
</template>

<script>
    import WaveBottom2 from '../../Layout/components/wave-bottom-2';
    import Particles from '../../Layout/components/particles-stars';

    export default {
        name: "Confirm",
        props: [
            'isMobile',
            'isTablet',
            'errorPassword',
            'errorPasswordMessage',
        ],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        components: {
            WaveBottom2,
            Particles,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
