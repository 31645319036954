<template>
    <footer>
        <section class="hero is-medium is-primary foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <article class="message is-centered">
                                <div class="message-body message-no-border message-border-color-9 is-size-5-custom">
                                    <div class="columns">
                                        <div class="column is-12 has-text-centered">
                                            <p>Powered by</p>
                                            <br>
                                            <a href="https://kmso.de" target="_blank">
                                                <img src="/images/kmso.svg" alt="KMSO" width="112" height="28">
                                            </a>
                                            <br>
                                            <br>
                                            <a href="/terms" class="text-blue">Terms of Use</a>
                                            <br>
                                            <a href="https://kmso.de/datenschutz" target="_blank" class="text-blue">Privacy</a>
                                            <br>
                                            <a href="https://kmso.de/impressum" target="_blank" class="text-blue">Imprint</a>
                                            <br>
                                            <br>
                                            <p>
                                               If you have any questions regarding the Boost Your App service or the integration, feel free to contact us under:
                                            </p>
                                            <a href="mailto:boost-your-app@kmso.de" rel="nofollow" class="text-blue">boost-your-app@kmso.de</a>
                                            <br>
                                            <br>
                                            <address>
                                                <p>KMSO IT-Dienstleistungen GmbH</p>
                                                <p>Steinbacher Straße 2</p>
                                                <p>65760 Eschborn</p>
                                                <p>Germany</p>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div v-if="this.isMobile">
                        <br>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                themes: ['dark', 'light'],
            }
        }
    }
</script>
