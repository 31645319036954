<template>
    <section>
        <h3 class="subtitle">App Review</h3>

        <div class="columns">
            <div class="column is-three-quarters">
                <div class="control">
                    <input v-model="specifiedAppId" class="input" type="text" placeholder="App Id">
                </div>
            </div>

            <div class="column is-one-quarter">
                <button type="button" class="button" v-on:click="loadAppInfoAndOpenModal(specifiedAppId)">Display</button>
            </div>
        </div>

        <h4 class="subtitle">Flagged Reviews</h4>

        <b-table
            v-if="data.length > 0"
            :data="data"
            paginated
            per-page="15"
        >
            <b-table-column
                field="startdate"
                label="Package"
                sortable
                v-slot="props"
            >
                {{ props.row.app_package }}
            </b-table-column>

            <b-table-column
                field="action"
                label="Action"
                sortable
                v-slot="props"
            >
                <div class="buttons">
                    <button class="button" v-on:click="loadAppInfoAndOpenModal(props.row.id)">Check</button>
                </div>
            </b-table-column>
        </b-table>

        <div v-else>There are currently no apps flagged for review.</div>

        <div v-if="app !== null" class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">App Review</p>
                    <button class="delete" aria-label="close" v-on:click="closeModal" :disabled="sending"></button>
                </header>
                <section class="modal-card-body">
                    <img alt="qrcode" :src="'data:image/png;base64,' + this.app.qrcode" />

                    <hr />

                    <div class="select">
                        <select v-model="lockApp">
                            <option value="0">Ok</option>
                            <option value="1">Lock</option>
                        </select>
                    </div>

                    <div v-if="lockApp === '1'">
                        <hr />

                        <div class="columns">
                            <div class="column is-full">
                                <div class="select is-multiple">
                                    <select id="selectForLockReasons" v-model="selectedLockReasons" multiple size="8">
                                        <option v-for="lockReason in lockReasons" :value="lockReason.id">
                                            {{ lockReason.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="columns" v-if="errorDuringLock">
                            <div class="column is-full">
                                <div class="select is-multiple">
                                    <div class="notification is-danger">
                                        No lock reason has been selected.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" :class="{ 'is-loading': sending }" :disabled="sending" v-on:click="saveDecision">Save</button>
                    <button class="button" v-on:click="closeModal" :disabled="sending">Close</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "IconsToReviewList",
    mounted() {
        this.loadLockReasons();
        this.loadAppsToCheck();
    },
    data() {
        return {
            data: [],
            sending: false,
            app: null,
            lockApp: '0',
            lockReasons: [],
            selectedLockReasons: [],
            specifiedAppId: null,
            errorDuringLock: false,
        }
    },
    methods: {
        loadLockReasons() {
            axios.get('/review/lock-reasons')
            .then(response => {
                this.lockReasons = response.data;
            })
        },
        loadAppsToCheck() {
            axios.get('/review/apps-to-check')
            .then(result => {
                this.data = result.data;
            })
            .catch(error => {
                console.log(error);
            });
        },
        loadAppInfoAndOpenModal(appId) {
            axios.get('/review/apps-to-check/' + appId)
            .then(response => {
                this.app = response.data;
                this.openModal();
            })
            .catch(error => {if(error.response.status === 404) {
                    alert('App id not found.');
                }
            });
        },
        saveDecision() {
            if(this.app !== null) {
                this.errorDuringLock = false;
                axios.post('/review/lock-reasons', {
                    app_id: this.app.id,
                    lock_app: this.lockApp,
                    lock_reasons: this.selectedLockReasons,
                })
                .then(response => {
                    if(response.status === 200) {
                        this.loadAppsToCheck();
                        this.closeModal();
                    }
                })
                .catch(error => {
                    if(error.response.status === 400) {
                        this.errorDuringLock = true;
                    }
                })
            }
        },
        openModal() {
            this.modalActive = true;
        },
        closeModal() {
            this.app = null;
            this.lockApp = '0';
            this.specifiedAppId = null;
            this.selectedLockReasons = [];
            this.errorDuringLock = false;
        }
    }
}
</script>

<style scoped>

</style>
