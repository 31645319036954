<template>
    <section class="hero is-medium">
        <div class="hero-body">
            <div class="columns">
                <div class="column is-12">
                    <h1 class="title has-text-centered">App Settings</h1>
                    <div v-if="appInfo !== null">
                        <article class="message is-warning" v-if="appInfo.review_status.display_name === 'not started' || appInfo.review_status.display_name === 'in progress' || (appInfo.review_status.display_name === 'in progress' && appInfo.review_store !== 'ok') || (appInfo.review_status.display_name === 'ok' && appInfo.is_active === 0)">
                            <div class="message-body">
                                <div v-if="appInfo.review_status.display_name === 'not started'">
                                    <p class="is-size-5-custom">To get your app activated you will need to upload at least one icon. <br><br> After that start the review process by clicking the "Start Review" button in the <i>Manage App Settings</i> section below or  <a href="#" class="text-blue" v-on:click="startReview">here</a>.</p>
                                </div>
                                <div v-if="appInfo.review_status.display_name === 'in progress'">
                                    <p class="is-size-5-custom">The review process is in progress. You can check the review status below by clicking the <b-icon icon="information-outline" size="is-small"></b-icon> in the <i>Manage App Settings</i> section or by clicking <a href="#" class="text-blue" v-on:click="openReviewStatusModal">here</a>.</p>
                                </div>
                                <div v-if="appInfo.review_status.display_name === 'in progress' && appInfo.review_store !== 'ok'">
                                    <br>
                                    <p class="is-size-5-custom">Please be sure to start the "Check Store"-process by clicking the button in the <i>Manage App Settings</i> section below or by clicking <a href="#" class="text-blue" v-on:click="checkStore">here</a>.</p>
                                </div>
                                <div v-if="appInfo.review_status.display_name === 'ok' && appInfo.is_active === 0">
                                    <p class="is-size-5-custom">Your app is not active. You can set your app active below in the <i>Manage App Settings</i> section.</p>
                                </div>
                            </div>
                        </article>

                        <article class="message is-danger" v-if="appInfo.is_locked === 1">
                            <div class="message-body">
                                 <p class="is-size-5-custom">Your app is locked and will not be displayed in apps of other users. <a href="#" class="text-blue" v-on:click="showLockReasons">Show more</a></p>
                            </div>
                        </article>
                    </div>
                    <hr>
                    <div class="columns">
                        <div class="column is-2">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Upload App Icons</strong></h2>
                                    <app-icon-upload
                                        v-if="iconUploadVisible"
                                        :app-id="appId"
                                        v-on:reload-app-icons-list="reloadAppIconList"
                                    ></app-icon-upload>
                                </div>
                            </article>
                        </div>
                        <div class="column is-10">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Manage App Icons</strong></h2>
                                    <app-icon-list
                                        :app-id="appId"
                                        ref="appIconList"
                                        v-on:reload-active-icon="reloadActiveIcon"
                                        v-on:show-icon-upload="showIconUpload"
                                        v-on:hide-icon-upload="hideIconUpload"
                                    ></app-icon-list>
                                </div>
                            </article>
                        </div>
                    </div>
                    <hr>
                    <div class="columns">
                        <div class="column is-2 has-text-centered">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Current App Icon or Placeholder</strong></h2>
                                    <app-icon-active
                                        ref="activeIcon"
                                        :app-id="appId"
                                    ></app-icon-active>
                                </div>
                            </article>
                        </div>
                        <div class="column" :class="{ 'is-5' : appInfo !== null && appInfo.review_status.display_name === 'ok', 'is-10' : appInfo !== null && appInfo.review_status.display_name !== 'ok' }">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Manage App Settings</strong></h2>
                                    <app-overview
                                        ref="appOverview"
                                        :app-id="appId"
                                        :user-data="userData"
                                        v-on:reload-app-icons-list="reloadAppIconList"
                                        v-on:reload-app-process="reloadAppProcess"
                                    ></app-overview>
                                </div>
                            </article>

                            <article v-if="this.user.type === 0" class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Admin Options</strong></h2>
                                    <admin-options
                                        :app-id="appId"
                                    ></admin-options>
                                </div>
                            </article>
                        </div>
                        <div class="column is-5" v-if="appInfo !== null && appInfo.review_status.display_name === 'ok'">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <h2 class="subtitle has-text-centered"><strong>Manage Framework Options</strong></h2>
                                    <p>Configure the Boost Your App Framework of your app. For each space you can choose one of these options:</p>
                                    <br>
                                    <ul>
                                        <li>All (display apps of other developers)</li>
                                        <li>Own (select one of your own apps where <strong>Internal Linking</strong> is activated)</li>
                                        <li>Closed (display no apps)</li>
                                    </ul>
                                    <br>
                                    <framework
                                        :app-id="appId"
                                    ></framework>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="lockReasons.length > 0" class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">App Locked</p>
                    <button class="delete" aria-label="close" v-on:click="hideLockReasons"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p>
                            Your app has been locked and will not be displayed in apps of other users.
                            <span v-if="lockReasons.length === 1">The reason is:</span>
                            <span v-if="lockReasons.length > 1">The reasons are:</span>
                        </p>

                        <ul>
                            <li v-for="lockReason in lockReasons">
                                {{ lockReason }}
                            </li>
                        </ul>

                        <p>
                            If you do not agree with our decision, you may send an email to us with the subject containing
                            your app id ({{ this.appId }}) and the reason for your inquiry. Please explain why your app
                            should be unlocked and we will re-check it.
                        </p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" v-on:click="hideLockReasons">Close</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
import AppIconUpload from "./AppIconUpload";
import AppIconActive from "./AppIconActive";
import AppOverview from "./AppOverview";
import AppIconList from "./AppIconList";
import Framework from "./Framework";
import AdminOptions from "./AdminOptions";

export default {
    components: {
        AppIconUpload,
        AppIconActive,
        AppOverview,
        AppIconList,
        Framework,
        AdminOptions,
    },
    name: "IndexApp",
    props: [
        'appId',
        'userData',
    ],
    data() {
        return {
            user: JSON.parse(this.userData),
            iconUploadVisible: false,
            appInfo: null,
            lockReasons: [],
        }
    },
    methods: {
        showLockReasons() {
            axios.get('/app/' + this.appId + '/lock-reasons')
            .then(response => {
                this.lockReasons = response.data;
            });
        },
        hideLockReasons() {
            this.lockReasons = [];
        },
        reloadActiveIcon() {
            this.$refs.activeIcon.loadActiveAppIcon();
        },
        reloadAppIconList() {
            this.$refs.appIconList.getAppIcons();
        },
        reloadAppProcess(data) {
            this.appInfo = data;
        },
        showIconUpload() {
            this.iconUploadVisible = true;
        },
        hideIconUpload() {
            this.iconUploadVisible = false;
        },
        openReviewStatusModal() {
            this.$refs.appOverview.openReviewStatusModal();
        },
        startReview() {
            this.$refs.appOverview.startReview();
        },
        checkStore() {
            this.$refs.appOverview.checkStore();
        },
    }
}
</script>

<style scoped>

</style>
