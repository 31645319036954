<template>
<section v-if="appInfo !== null">
    <table class="table is-fullwidth">
        <tbody>
        <tr>
            <td>Name</td>
            <td>
                <div class="field">
                    <div class="control">
                        <input class="input" :class="{ 'is-danger': errorAppName }" type="text" v-model="appInfo.app_name" placeholder="App Name">
                    </div>
                </div>
            </td>
        </tr>

        <tr>
            <td>Description</td>
            <td>
                <textarea class="textarea" v-model="appInfo.app_description" placeholder="App Description"></textarea>
            </td>
        </tr>

        <tr>
            <td>Package</td>
            <td>
                <div style="font-family: monospace;">
                    {{ appInfo.app_package }}
                    <a v-on:click="copyToClipboard('copy-to-clipboard-app-package')" title="copy to clipboard">
                        <b-icon :icon="iconCopyAppPackage" size="is-small"></b-icon>
                    </a>
                </div>
            </td>
        </tr>

        <tr>
            <td>API Key</td>
            <td>
                <div style="font-family: monospace;">
                    {{ appInfo.api_key }}
                    <a v-on:click="copyToClipboard('copy-to-clipboard-api-key')" title="copy to clipboard">
                        <b-icon :icon="iconCopyApiKey" size="is-small"></b-icon>
                    </a>
                </div>
            </td>
        </tr>

        <tr v-if="appInfo.review_status.level === 'green'">
            <td>Active</td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="appInfo.is_active">
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>

        <tr v-if="this.$featureTransactions && appInfo.review_status.level === 'green'">
            <td>
                Point Mode

                <a href="#" v-on:click="setPointModeModal(true)">
                    <b-icon icon="information-outline" size="is-small"></b-icon>
                </a>
            </td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="appInfo.point_mode">
                            <option value="0">Both</option>
                            <option value="1">Collect</option>
                            <option value="2">Boost</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>

        <tr v-if="appInfo.review_status.level === 'green'">
            <td>
                Internal Linking

                <a href="#" v-on:click="setInternalLinkingModal(true)">
                    <b-icon icon="information-outline" size="is-small"></b-icon>
                </a>
            </td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="appInfo.internal_linking_active">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>Review
                <a href="#" v-on:click="openReviewStatusModal">
                    <b-icon icon="information-outline" size="is-small"></b-icon>
                </a></td>
            <td>
                <span class="tag" :class="appInfo.review_status.class">
                    {{ appInfo.review_status.display_name }}
                </span>

                <span v-if="appInfo.review_status.level !== 'green'">
                    {{ appInfo.review_ok_count }}



                    <br v-if="(appInfo.review_status.display_name === 'not started') || (appInfo.review_status.display_name === 'in progress' && appInfo.review_store !== 'ok')">

                    <button
                        v-if="appInfo.review_status.display_name === 'not started'"
                        class="button is-small is-primary button-margin-top"
                        type="button"
                        v-on:click="startReview"
                        :disabled="reviewStarting"
                    >
                        Start Review
                    </button>

                    <button
                        v-if="appInfo.review_status.display_name === 'in progress' && this.user.type === 0"
                        class="button is-small is-primary button-margin-top"
                        type="button"
                        v-on:click="activateAppAdmin"
                        :disabled="reviewStarting"
                    >
                        Activate (Admin)
                    </button>

                    <store-check-app
                        ref="storeCheckApp"
                        v-if="appInfo.review_status.display_name === 'in progress' && appInfo.review_store !== 'ok'"
                        :app-id="appId"
                        v-on:store-check-ok="getAppInfo"
                    ></store-check-app>
                </span>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="notification is-danger" v-if="errorValidation.length > 0">
        <ul>
            <li v-for="error in errorValidation">
                {{ error }}
            </li>
        </ul>
    </div>

    <div class="notification is-danger" v-if="errorReviewStart.length > 0">
        <div class="block">
            The system was not able to start the review process:
        </div>

        <div class="block">
            <ul>
                <li v-for="error in errorReviewStart">
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>

    <div class="notification is-danger" v-if="generalError">
        Saving was not successful. Please refresh the page and try again.
    </div>

    <div class="columns">
        <div class="column has-text-centered">
            <button class="button is-primary" :class="updateRequestResultClass" type="button" v-on:click="checkUpdateApp" :disabled="updateRequestActive">Save Changes</button>
        </div>
    </div>

    <div class="modal" :class="{ 'is-active': pointModeModal }">
        <div class="modal-background" v-on:click="setPointModeModal(false)"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Point Mode</p>
                <button class="delete" aria-label="close" v-on:click="setPointModeModal(false)"></button>
            </header>
            <section class="modal-card-body">
                <p>You can choose between different modes to change the framework's behavior.</p>
                <br />

                <h3 class="subtitle">Explanation</h3>

                <p><strong>Both</strong> allows your app to be seen from other apps while your app also collects points
                from its own framework. So, your app is able to collect points and to spend points for clicks on your
                own app.</p>
                <br />
                <p><strong>Collect</strong> means that your app is only collecting points by displaying other apps
                    in its own framework. An app in this mode will not show up in other app frameworks.</p>
                <br />
                <p><strong>Boost</strong> is for your app only being displayed by other apps. You will only spend points
                with an app in this mode.</p>

                <br />
                <h3 class="subtitle">Tip</h3>

                <p>If you have multiple apps on your account and some of them are performing well, you can switch their
                point mode to <strong>Collect</strong>. If you have a new app on your account which you want to get
                views for fast, you can set the point mode to <strong>Boost</strong>. So, you will collect points with
                your strong apps to boost your new app!</p>
            </section>
            <footer class="modal-card-foot">
                <button class="button" v-on:click="setPointModeModal(false)">Close</button>
            </footer>
        </div>
    </div>

    <div class="modal" :class="{ 'is-active': internalLinkingModal }">
        <div class="modal-background" v-on:click="setInternalLinkingModal(false)"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Internal Linking</p>
                <button class="delete" aria-label="close" v-on:click="setInternalLinkingModal(false)"></button>
            </header>
            <section class="modal-card-body">
                <p>This functionality allows this app to be displayed for internal linking.</p>

                <br />

                <h3 class="subtitle">Explanation</h3>
                <p><strong>Internal Linking</strong> allows you to display your own apps in your implemented framework.
                To display an own app this option has to be activated and from another of your apps you have to configure a framework slot with type <strong>Own</strong>.</p>

                <br />

                <h3 v-if="this.$featureTransactions" class="subtitle">Tip</h3>
                <p v-if="this.$featureTransactions">You can use the framework to display own apps and other apps (for earning points) simultaneously
                by configuring your framework behavior.</p>
            </section>
            <footer class="modal-card-foot">
                <button class="button" v-on:click="setInternalLinkingModal(false)">Close</button>
            </footer>
        </div>
    </div>

    <div class="modal" :class="{ 'is-active': internalLinkingWarningModal }">
        <div class="modal-background" v-on:click="setInternalLinkingWarningModal(false)"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Deactivate Internal Linking</p>
                <button class="delete" aria-label="close" v-on:click="setInternalLinkingWarningModal(false)"></button>
            </header>
            <section class="modal-card-body">
                <p>If you turn off <strong>Internal Linking</strong> or set your app to <strong>Inactive</strong>, this
                    app will be removed from the framework of your other apps that are referencing to this one.</p>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" v-on:click="updateApp">Save Changes</button>
                <button class="button is-danger" v-on:click="setInternalLinkingWarningModal(false)">Abort</button>
            </footer>
        </div>
    </div>

    <div v-if="reviewStatus !== null" class="modal" :class="{ 'is-active': reviewStatusModal }">
        <div class="modal-background" v-on:click="closeReviewStatusModal"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Review Status</p>
                <button class="delete" aria-label="close" v-on:click="closeReviewStatusModal"></button>
            </header>
            <section class="modal-card-body">
                <p>To activate your app, the review must be completed. Here, you can see the current status of your app.</p>
                <br />
                <table class="table is-full-width">
                    <tbody>
                    <tr>
                        <td>Connection</td>
                        <td>
                            <span class="tag" :class="reviewStatus.review_connection.class">
                                {{ reviewStatus.review_connection.display_name }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Store</td>
                        <td>
                            <span class="tag" :class="reviewStatus.review_store.class">
                                {{ reviewStatus.review_store.display_name }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Icon</td>
                        <td>
                            <span class="tag" :class="reviewStatus.review_icon.class">
                                {{ reviewStatus.review_icon.display_name }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h3 class="subtitle">Explanation</h3>

                <p>There are three different reviews that have to be completed to activate your app.</p>
                <br />
                <p><strong>Connection</strong> can be done by yourself to test if you implemented the framework in
                you app correctly. Just follow the instructions to implement the package and open your app. The status
                should then change to "ok"</p>
                <br />
                <p><strong>Store</strong> is a manual check if your app is present in the Google Play Store. If your app
                can be found there, the status will be set to "ok".</p>
                <br />
                <p><strong>Icon</strong> displays if your icon is ok or not. After you have uploaded an icon, it will
                be reviewed if the shown content is compliant. If so, the status will be set to "ok". You can upload
                multiple icons for your app and change between them. Every new icon will be checked. If an icon has
                been checked, you can select it without any further review.</p>
            </section>
            <footer class="modal-card-foot">
                <button class="button" v-on:click="closeReviewStatusModal">Close</button>
            </footer>
        </div>
    </div>

    <input type="hidden" id="copy-to-clipboard-app-package" :value="appInfo.app_package"></input>
    <input type="hidden" id="copy-to-clipboard-api-key" :value="appInfo.api_key"></input>
</section>
</template>

<script>
import StoreCheckApp from "./StoreCheckApp";

export default {
    components: {
        StoreCheckApp,
    },
    name: "AppOverview",
    props: [
        'appId',
        'userData'
    ],
    data() {
        return {
            appInfo: null,
            updateRequestActive: false,
            updateRequestResultClass: '',
            updateRequestResultTimeoutReference: null,
            errorValidation: [],
            errorAppName: false,
            generalError: false,
            reviewStatusModal: false,
            errorReviewStart: [],
            reviewStarting: false,
            reviewStatus: null,
            iconCopyAppPackage: 'content-copy',
            iconCopyApiKey: 'content-copy',
            pointModeModal: false,
            internalLinkingModal: false,
            internalLinkingBase: 1,
            internalLinkingWarningModal: false,
            isActiveBase: 1,
            user: JSON.parse(this.userData)
        }
    },
    mounted() {
        this.getAppInfo();
    },
    methods: {
        setInternalLinkingModal(setTo) {
            this.internalLinkingModal = setTo;
        },
        setPointModeModal(setTo) {
            this.pointModeModal = setTo;
        },
        setInternalLinkingWarningModal(setTo) {
            this.internalLinkingWarningModal = setTo;

            if(!setTo) {
                this.appInfo.internal_linking_active = this.internalLinkingBase;
                this.appInfo.is_active = this.isActiveBase;
            }
        },
        copyToClipboard(id) {
            this.iconCopyAppPackage = 'content-copy';
            this.iconCopyApiKey = 'content-copy';

            let copyInput = document.getElementById(id);
            copyInput.setAttribute('type', 'text')
            copyInput.select();
            document.execCommand('copy');
            copyInput.setAttribute('type', 'hidden');

            if(id === 'copy-to-clipboard-app-package') {
                this.iconCopyAppPackage = 'check';
            } else if(id === 'copy-to-clipboard-api-key') {
                this.iconCopyApiKey = 'check';
            }
        },
        activateAppAdmin() {
            console.log(this.appId);

            axios.post('/app/admin/activate/' + this.appId)
            .then(response => {
                console.log(response.status)
                if(response.status === 200) {
                    location.reload();
                }
            });
        },
        startReview() {
            this.reviewStarting = true;
            this.errorReviewStart = [];

            axios.post('/app/start-review', {
                app_id: this.appId,
            })
            .then(response => {
                if(response.status === 200) {
                    this.getAppInfo();
                    this.$emit('reload-app-icons-list');
                }
            })
            .catch(error => {
                if(error.response.status === 400) {
                    this.errorReviewStart = error.response.data;
                }
            })
            .finally(() => {
                this.reviewStarting = false;
            });
        },
        openReviewStatusModal() {
            axios.get('/app/get/' + this.appId + '/review-status')
            .then(response => {
                this.reviewStatus = response.data;

                this.reviewStatusModal = true;
                this.getAppInfo();
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        closeReviewStatusModal() {
            this.reviewStatusModal = false;
        },
        checkUpdateApp() {
            let internalLinkingActive = parseInt(this.appInfo.internal_linking_active);
            let isActive = parseInt(this.appInfo.is_active);

            if(internalLinkingActive === 0 && this.internalLinkingBase === 1 || isActive === 0 && this.isActiveBase === 1) {
                this.setInternalLinkingWarningModal(true);
            } else {
                this.updateApp();
            }
        },
        updateApp() {
            if(this.updateRequestResultTimeoutReference !== null) {
                clearTimeout(this.updateRequestResultTimeoutReference);
                this.updateRequestResultTimeoutReference = null;
            }

            this.updateRequestActive = true;
            this.errorValidation = [];
            this.errorAppName = false;
            this.generalError = false;

            axios.post('/app/update', {
                app_id: this.appId,
                app_name: this.appInfo.app_name,
                app_description: this.appInfo.app_description,
                is_active: this.appInfo.is_active,
                point_mode: parseInt(this.appInfo.point_mode),
                internal_linking_active: parseInt(this.appInfo.internal_linking_active),
            })
            .then(response => {
                this.updateRequestActive = false;
                this.updateRequestResultClass = 'is-primary';

                if(this.internalLinkingWarningModal) {
                    this.internalLinkingWarningModal = false;
                }

                this.isActiveBase = parseInt(this.appInfo.is_active);
                this.internalLinkingBase = parseInt(this.appInfo.internal_linking_active);

                this.updateRequestResultTimeoutReference = setTimeout(() => {
                    this.updateRequestResultClass = '';
                }, 2500);
            })
            .catch(error => {
                if(error.response.status === 422) {
                    let errors = error.response.data.errors;

                    Object.keys(errors).forEach(value => {
                        switch(value) {
                            case 'app_name':
                                this.errorAppName = true;
                                break;
                        }

                        errors[value].forEach(innerValue => {
                            this.errorValidation.push(innerValue);
                        });
                    });
                } else if(error.response.status === 400) {
                    this.generalError = true;
                }

                this.updateRequestActive = false;

                this.updateRequestResultClass = 'is-danger';

                this.updateRequestResultTimeoutReference = setTimeout(() => {
                    this.updateRequestResultClass = '';
                }, 2500);
            });
        },
        getAppInfo() {
            axios.get('/app/get/' + this.appId)
            .then(response => {
                this.appInfo = response.data;

                this.internalLinkingBase = this.appInfo.internal_linking_active;
                this.isActiveBase = this.appInfo.is_active;

                this.$emit('reload-app-process', this.appInfo);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        checkStore() {
            this.$refs.storeCheckApp.checkApp();
        }
    }
}
</script>

<style scoped>

</style>
