<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/images/terms.svg" alt="Terms" class="is-transparent-2 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white">
                            Terms of Use
                        </h1>
                        <p></p>
                        <h2 class="subtitle has-text-white">
                            Terms of Use for Boost Your App
                        </h2>
                        <br>
                        <div class="has-text-centered">
                            <a @click="$moveToAnchor('allgemein')"
                                 class="button is-primary is-size-5-custom">
                                Let's Go
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/images/terms.svg" alt="Terms" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white">
                            Terms of Use
                        </h1>
                        <p></p>
                        <h2 class="subtitle has-text-white">
                            Terms of Use for Boost Your App
                        </h2>
                        <br>
                        <div class="has-text-centered">
                            <a @click="$moveToAnchor('allgemein')"
                                 class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                Let's Go
                            </a>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein-anchor" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class=" is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">Terms of Use</h3>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>1. Scope, Terms and Definitions</strong></h4>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">The following terms of use apply exclusively.</p>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">These terms of use regulate the way in which the service is made available by Boost Your App.</p>
                        <br>
                        <p>KMSO IT-Dienstleistungen GmbH (<a href="https://kmso.de" target="_blank">https://kmso.de</a>) (hereinafter: provider) acts as the provider of the Boost Your App website and the Boost Your App service (hereinafter: service). The service enables users to integrate the Boost Your App Framework into their apps and manage several options regarding the Boost Your App Framework in the Boost Your App Dashboard.</p>
                        <br>
                        <p>It is possible to register for the Boost Your App service. Registered users (hereinafter: user or users) with an existing and active user account have the opportunity to use the service.</p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>2. Pricing</strong></h4>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">The use of the Boost Your App service is free of charge.</p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>3. Duration and Deadlines</strong></h4>
                        <br>
                        <p>The user's account can be deleted at any time. Further information on this can be found in the <a href="https://kmso.de/datenschutz" target="_blank">Privacy Policy</a> Article 24 Data Protection Provisions about the Application and use of Boost Your App, b) Deletion of the User Account.</p>
                        <br>
                        <p>Users have the right to revoke this consent at any time and without giving reasons. It is sufficient for the user to send an email to <a href="mailto:boost-your-app@kmso.de" rel="nofollow">boost-your-app@kmso.de</a>. This email must be sent from the email address used during registration. The user's declaration of intent to withdraw consent must be evident from the email. By revoking the consent, all data stored by the user and the user account are irrevocably deleted.</p>
                        <br>
                        <p>Every added app by any user can be rejected or deleted at any time by the provider without giving reasons. Among other things, this can happen if an app was uploaded by an unauthorized user or without the necessary rights. Also, every uploaded icon can be rejected or deleted without giving reasons after they were approved before if a violation (e.g. copyright) arises at a later point. </p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>4. User Responsibilities</strong></h4>
                        <br>
                        <p>For every uploaded icon, the user confirms to have the necessary rights to upload and share the icon. Furthermore, the user confirms to have the necessary rights for every added app. The user confirms to have the necessary rights and too authorize the provider to use every uploaded icon and added app for the service of Boost Your App. This affects, for example, the display of icons or the linking of added apps in apps that have integrated the Boost Your App Framework.</p>
                        <br>
                        <p>The user agrees to the <a @click="$moveToAnchor('guidelines')">Guidelines</a>. Furthermore, the user confirms that every uploaded icon and every added app that goes with it complies with the <a href="https://play.google.com/about/developer-content-policy/" target="_blank" rel="nofollow">Google Play Policies</a>.</p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>5. Liability</strong></h4>
                        <br>
                        <p>The provider assumes no liability or guarantee for the functionality, correctness and any damage that may arise through the use of the Boost Your App service. The use of the Boost Your App service is at your own risk. Claims for damages on the part of the user are excluded.</p>
                        <br>
                        <p>The user assumes liability for every uploaded icon and added app and assumes all claims for damages by third parties that are made against the provider and assumes all costs that arise for the provider in such a process. The provider cannot be held responsible for uploaded icons or added apps by users. If a violation becomes known, uploaded icons and added apps are deleted immediately and may corresponding user accounts will be deleted. If a user account is deleted the service of Boost Your App may not work anymore in added apps of the specific user where the Boost Your App Framework is integrated. This can impact the behavior of the specific app and the app may not work as expected anymore.</p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>6. Disclaimer</strong></h4>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">The terms of use may change at any time and without further notification.</p>
                    </div>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="white-background"></WaveFooter>
        <footer-element
            :is-mobile="this.isMobile"
            :is-tablet="this.isTablet">
        </footer-element>
    </section>
</template>

<script>
    import WaveFooter from './components/wave-footer-bottom';
    import WaveBottom from './components/wave-bottom';
    import Particles from './components/particles-stars';

    export default {
        name: "App",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                gradle: true,
                boxButtonIsVisible: [ false, false, false, false, false ],
                boxBorder: [ false, false, false, false, false ],
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
        },
        methods: {
            changeButtonBoxText(index, text) {
                const buttonbox = this.$refs[`buttonbox${index}`];
                buttonbox.innerText = text;
            },
            mouseBox(index, state) {
                this.$set(this.boxButtonIsVisible, index, state);
            },
            mouseBoxButton(index, state) {
                this.$set(this.boxBorder, index, state);

                if (!state) {
                    this.changeButtonBoxText(index, "Copy");
                }
            },
            copyText(index) {
                this.changeButtonBoxText(index, "Copied");

                const el = this.$refs[`box${index}`];

                if (document.body.createTextRange) {
                    const range = document.body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                    document.execCommand('copy');
                } else if (window.getSelection) {
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.selectNodeContents(el);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('copy');
                }

                if (window.getSelection) {
                    window.getSelection().removeAllRanges();
                }
                else if (document.selection) {
                    document.selection.empty();
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
