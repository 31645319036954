<template>
<section>
    <h3 class="subtitle">App Info</h3>
    <p>Apps on platform: {{ apps }}</p>

    <b-table
        v-if="data.length > 0"
        :data="data"
        paginated
        per-page="10"
    >
        <b-table-column
            field="app_name"
            label="App"
            sortable
            v-slot="props"
        >
            {{ props.row.app_name }}
        </b-table-column>

        <b-table-column
            field="clicks_incoming"
            sortable
            numeric
            label="Clicks In"
            v-slot="props"
        >
            {{ props.row.clicks_incoming }}
        </b-table-column>

        <b-table-column
            field="clicks_outgoing"
            sortable
            numeric
            label="Clicks Out"
            v-slot="props"
        >
            {{ props.row.clicks_outgoing }}
        </b-table-column>

        <b-table-column
            field="icons_displayed"
            sortable
            numeric
            label="Icons Displayed"
            v-slot="props"
        >
            {{ props.row.icons_displayed }}
        </b-table-column>

        <b-table-column
            field="icon_views"
            sortable
            numeric
            label="Icon Views"
            v-slot="props"
        >
            {{ props.row.icon_views }}
        </b-table-column>

        <b-table-column
            field="app_views"
            sortable
            numeric
            label="App Views"
            v-slot="props"
        >
            {{ props.row.views }}
        </b-table-column>

        <b-table-column
            field="old_to_new"
            sortable
            numeric
            label="Old/New"
            v-slot="props"
        >
            {{ props.row.old_to_new }}
        </b-table-column>

        <b-table-column
            field="points"
            sortable
            numeric
            label="Points"
            v-slot="props"
        >
            {{ props.row.points }}
        </b-table-column>
    </b-table>
</section>
</template>

<script>
export default {
    name: "AppList",
    data() {
        return {
            apps: 0,
            data: [],
        }
    },
    mounted() {
        this.loadAppInfo();
    },
    methods: {
        loadAppInfo() {
            axios.get('/admin/app-info')
                .then(response => {
                    this.data = response.data.data;
                    this.apps = response.data.amount;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }
}
</script>

<style scoped>

</style>
