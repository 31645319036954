<template>
<section>
    <table class="table">
        <tr>
            <td>Icons</td>
            <td>{{ icons.amount }} / {{ icons.limit }}</td>
        </tr>
    </table>

    <b-table
        v-if="data.length > 0"
        :data="data"
        paginated
        per-page="15"
    >
        <b-table-column
            field="select"
            label="Select"
            v-slot="props"
        >
            <div class="control">
                <label class="radio">
                    <input
                        type="radio"
                        name="chosen_icon"
                        :disabled="props.row.review_status.display_name !== 'ok'"
                        :checked="props.row.is_active === 1"
                        v-on:change="chosenIcon(props.row.id)"
                    >
                </label>
            </div>
        </b-table-column>

        <b-table-column
            field="icon"
            label="Icon"
            sortable
            v-slot="props"
        >
            <div class="control">
                <img
                    style="width: 50px; height: auto;"
                    :src="props.row.icon_url"
                >
            </div>
        </b-table-column>

        <b-table-column
            field="review_status"
            label="Review"
            sortable
            v-slot="props"
        >
            <span class="tag" :class="props.row.review_status.class">
                {{ props.row.review_status.display_name }}
            </span>
        </b-table-column>

        <b-table-column
            field="action"
            label="Action"
            v-slot="props"
        >
            <div class="buttons">
                <button
                    v-if="props.row.review_startable"
                    class="button is-primary is-small"
                    type="button"
                    v-on:click="startIconReview(props.row.id)"
                >
                    Start Review
                </button>

                <button
                    class="button is-small"
                    type="button"
                    v-on:click="startDeletingIcon(props.row.id, props.row.icon_path, props.row.icon_name)"
                    :disabled="!props.row.is_deletable"
                >
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                </button>
            </div>
        </b-table-column>
    </b-table>

    <br>

    <div class="columns">
        <div class="column has-text-centered">
            <button
                v-if="data.length > 0"
                type="button"
                class="button is-primary"
                :disabled="!this.canSaveActiveIcon"
                v-on:click="changeActiveIcon"
            >
                Activate selected Icon
            </button>
        </div>
    </div>

    <div class="notification is-warning" v-if="noIconWarning">
        You have not uploaded any icon for this app, yet.
    </div>

    <div class="modal" :class="{ 'is-active': showDeleteModal }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Delete Icon</p>
                <button class="delete" aria-label="close" v-on:click="setDeleteModal(false)"></button>
            </header>
            <section class="modal-card-body">
                <div class="block">
                    <p>Do you really want to delete the icon?</p>
                </div>

                <div class="block">
                    <p class="image is-3by3">
                        <img :src="deleteModalIconUrl" alt="Delete">
                    </p>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-danger" v-on:click="deleteAppIcon(deleteModalIconId)">Delete</button>
                <button class="button is-primary" v-on:click="setDeleteModal(false)">Cancel</button>
            </footer>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "AppIconList",
    props: [
        'appId'
    ],
    data() {
        return {
            data: [],
            noIconWarning: false,
            showDeleteModal: false,
            deleteModalIconUrl: '/images/icon_placeholder.png',
            deleteModalIconId: null,
            activeIconId: null,
            chosenIconId: null,
            canSaveActiveIcon: false,
            icons: {
                amount: '-',
                limit: '-',
            }
        }
    },
    mounted() {
        this.getAppIcons();
    },
    methods: {
        startIconReview(iconId) {
            axios.post('app/icon/start-review', {
                app_id: this.appId,
                icon_id: iconId
            })
            .then(response => {
                if(response.status === 200) {
                    this.getAppIcons();
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        changeActiveIcon() {
            axios.post('app/icon/change', {
                app_id: this.appId,
                icon_id: this.chosenIconId,
            })
            .then(response => {
                if(response.status === 200) {
                    this.getAppIcons();
                    this.$emit('reload-active-icon');
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        chosenIcon(iconId) {
            this.canSaveActiveIcon = iconId !== this.activeIconId;
            this.chosenIconId = iconId;
        },
        setDeleteModal(show) {
            this.showDeleteModal = show;
        },
        startDeletingIcon(iconId, iconPath, iconName) {
            this.deleteModalIconUrl = '/storage/icons/' + iconPath + '/' + iconName;
            this.deleteModalIconId = iconId;
            this.setDeleteModal(true);
        },
        deleteAppIcon(iconId) {
            axios.delete('/app/' + this.appId + '/icon/' + iconId)
            .then(response => {
                if(response.status === 200) {
                    this.getAppIcons();
                    this.deleteModalIconUrl = '/images/icon_placeholder.png';
                    this.deleteModalIconId = null;
                    this.setDeleteModal(false);
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        getAppIcons() {
            this.noIconWarning = false;
            this.activeIconId = null;
            this.chosenIconId = null;
            this.canSaveActiveIcon = false;

            axios.get('/app/' + this.appId + '/icons')
            .then(response => {
                this.data = response.data.data;
                this.icons = response.data.icons;

                if(!this.icons.exceeded) {
                    this.$emit('show-icon-upload');
                } else {
                    this.$emit('hide-icon-upload');
                }

                this.data.forEach(icon => {
                    if(icon.is_active) {
                        this.activeIconId = icon.id;
                    }
                });

                if(this.data.length === 0) {
                    this.noIconWarning = true;
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        }
    }
}
</script>

<style scoped>

</style>
