<template>
    <div v-if="this.isMobile" class="wave-bottom-img flex-end width-100p foreground"></div>
    <div v-else class="line-height-0">
        <div class="wave-bottom-img pos-absolute pos-absolute-left-zero pos-absolute-bottom-zero"></div>
    </div>
</template>

<script>
    export default {
        name: 'Wave-Bottom',
        props: [
            'isMobile',
        ],
    }
</script>
