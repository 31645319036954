<template>
    <section v-if="data.length > 0">
        <div class="columns">
            <div class="column is-12">
                <article class="message is-primary">
                    <div class="message-body">
                        <h2 class="subtitle has-text-left"><strong>Your Apps</strong></h2>
                        <b-table
                            :data="data"
                            :striped="true"
                            :hoverable="true"
                            :mobile-cards="true"
                            :bordered="true"
                            paginated
                            per-page="15"
                        >
                            <b-table-column
                                field="app_name"
                                label="App"
                                sortable
                                v-slot="props"
                            >
                                <b-tooltip v-if="props.row.is_locked === 1" label="App is Locked" type="is-danger">
                                    <span class="tag is-danger">
                                        <b-icon icon="lock-outline" size="is-small"></b-icon>
                                    </span>
                                </b-tooltip>

                                <b-tooltip v-if="props.row.is_locked === 0 && props.row.is_active === 1" label="App is Active" type="is-success">
                                    <span class="tag is-success">
                                        <b-icon icon="check-outline" size="is-small"></b-icon>
                                    </span>
                                </b-tooltip>

                                <b-tooltip v-if="props.row.is_locked === 0 && props.row.is_active === 0 && props.row.review_status === 'ok'" label="App is Inactive" type="is-info">
                                    <span class="tag is-info">
                                        <b-icon icon="minus-circle-outline" size="is-small"></b-icon>
                                    </span>
                                </b-tooltip>

                                <b-tooltip v-if="props.row.is_locked === 0 && (props.row.review_status === 'in_review_connection' || props.row.review_status === 'in_review_manual')" label="App is in Review" type="is-info">
                                    <span class="tag is-info">
                                        <b-icon icon="refresh" size="is-small"></b-icon>
                                    </span>
                                </b-tooltip>

                                <b-tooltip v-if="props.row.is_locked === 0 && props.row.review_status === 'not_started'" label="Review not Started Yet: go to your app's configuration page for more information." type="is-warning">
                                    <span class="tag is-warning">
                                        <b-icon icon="alert-decagram" size="is-small"></b-icon>
                                    </span>
                                </b-tooltip>
                                {{ props.row.app_name }}
                            </b-table-column>

                            <b-table-column
                                field="clicks_incoming"
                                sortable
                                numeric
                                label="Clicks In"
                                v-slot="props"
                            >
                                <b-tooltip label="Incoming clicks from other app frameworks" type="is-info">
                                    {{ props.row.clicks_incoming }}
                                </b-tooltip>
                            </b-table-column>

                            <b-table-column
                                field="clicks_outgoing"
                                sortable
                                numeric
                                label="Clicks Out"
                                v-slot="props"
                            >
                                <b-tooltip label="Outgoing clicks from your apps framework" type="is-info">
                                    {{ props.row.clicks_outgoing }}
                                </b-tooltip>
                            </b-table-column>

                            <b-table-column
                                field="icons_displayed"
                                sortable
                                numeric
                                label="Icons Displayed"
                                v-slot="props"
                            >
                                <b-tooltip label="The amount of icons that were displayed" type="is-info">
                                    {{ props.row.icons_displayed }}
                                </b-tooltip>
                            </b-table-column>

                            <b-table-column
                                field="icon_views"
                                sortable
                                numeric
                                label="Icon Views"
                                v-slot="props"
                            >
                                <b-tooltip label="The amount how often your apps icon was shown in other app frameworks" type="is-info">
                                    {{ props.row.icon_views }}
                                </b-tooltip>
                            </b-table-column>

                            <b-table-column
                                field="app_views"
                                sortable
                                numeric
                                label="App Views"
                                v-slot="props"
                            >
                                <b-tooltip label="First number indicates how often your app has been opened. The second number indicates new users. The third number indicates how many users reused your app." type="is-info">
                                    {{ props.row.views }} / {{ props.row.views_new }} / {{ props.row.views_old }}
                                </b-tooltip>
                            </b-table-column>

                            <b-table-column v-if="this.$featureTransactions"
                                            field="points"
                                            sortable
                                            numeric
                                            label="Points"
                                            v-slot="props"
                            >
                                {{ props.row.points }}
                            </b-table-column>

                            <b-table-column
                                label="Actions"
                                v-slot="props"
                            >
                                <div>
                                    <a class="button is-small" :href="'/portal/app/' + props.row.id">
                                        <b-icon icon="cog-outline" size="is-small"></b-icon>
                                    </a>

                                    <button
                                        v-if="props.row.review_status !== 'ok'"
                                        class="button is-small"
                                        disabled="true"
                                    >
                                        <b-icon icon="chart-line" size="is-small"></b-icon>
                                    </button>
                                    <a
                                        v-else
                                        class="button is-small"
                                        :href="'/portal/app/' + props.row.id + '/statistics'"
                                    >
                                        <b-icon icon="chart-line" size="is-small"></b-icon>
                                    </a>
                                </div>
                            </b-table-column>
                        </b-table>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AppListComponent",
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        this.getAll();
    },
    methods: {
        getAll() {
            axios.get('/app/get', {})
            .then(response => {
                console.log(response.data);
                this.data = response.data;
            })
            .catch(error => {
                console.log(error.response);
            });
        }
    }
}
</script>

<style scoped>

</style>
