<template>
<section>
    <h3 class="subtitle">Visitors</h3>

    <b-table
        v-if="data.length > 0"
        :data="data"
    >
        <b-table-column
            field="id"
            label="ID"
            numeric
            v-slot="props"
        >
            {{ props.row.id }}
        </b-table-column>

        <b-table-column
            field="user_id"
            label="User"
            numeric
            v-slot="props"
        >
            {{ props.row.user_id === null ? '-' : props.row.user_id }}
        </b-table-column>

        <b-table-column
            field="path"
            label="Path"
            v-slot="props"
        >
            {{ props.row.path }}
        </b-table-column>

        <b-table-column
            field="method"
            label="Method"
            v-slot="props"
        >
            {{ props.row.method }}
        </b-table-column>

        <b-table-column
            field="time"
            label="Time"
            v-slot="props"
        >
            {{ props.row.time }}
        </b-table-column>
    </b-table>
</section>
</template>

<script>
export default {
    name: "Visitors",
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        this.loadVisitors();
        setInterval(this.loadVisitors, 2000);
    },
    methods: {
        loadVisitors() {
            axios.get('/statistics/visitors')
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }
}
</script>

<style scoped>

</style>
