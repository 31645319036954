<template>
    <section class="has-text-centered">
        <div class="columns" v-if="!loading">
            <div class="column is-6 has-text-centered"> <!-- 1 -->
                <img :src="data.framework[0].icon_url">

                <div class="field">
                    <div class="label">Type</div>
                    <div class="control">
                        <div class="select">
                            <select v-model.number="data.framework[0].framework_type">
                                <option value="0">All</option>
                                <option value="1">Own</option>
                                <option value="2">Closed</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field" v-if="data.framework[0].framework_type === 1">
                    <div class="label">App</div>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.framework[0].framework_app" v-on:change="changeIcon(0)">
                                <option v-for="app in data.apps" v-bind:value="app.id">
                                    {{ app.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6 has-text-centered"> <!-- 2 -->
                <img :src="data.framework[1].icon_url">

                <div class="field">
                    <div class="label">Type</div>
                    <div class="control">
                        <div class="select">
                            <select v-model.number="data.framework[1].framework_type">
                                <option value="0">All</option>
                                <option value="1">Own</option>
                                <option value="2">Closed</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field" v-if="data.framework[1].framework_type === 1">
                    <div class="label">App</div>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.framework[1].framework_app" v-on:change="changeIcon(1)">
                                <option v-for="app in data.apps" v-bind:value="app.id">
                                    {{ app.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns" v-if="!loading">
            <div class="column is-6 has-text-centered"> <!-- 3 -->
                <img :src="data.framework[2].icon_url">

                <div class="field">
                    <div class="label">Type</div>
                    <div class="control">
                        <div class="select">
                            <select v-model.number="data.framework[2].framework_type">
                                <option value="0">All</option>
                                <option value="1">Own</option>
                                <option value="2">Closed</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field" v-if="data.framework[2].framework_type === 1">
                    <div class="label">App</div>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.framework[2].framework_app" v-on:change="changeIcon(2)">
                                <option v-for="app in data.apps" v-bind:value="app.id">
                                    {{ app.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6 has-text-centered"> <!-- 4 -->
                <img :src="data.framework[3].icon_url">

                <div class="field">
                    <div class="label">Type</div>
                    <div class="control">
                        <div class="select">
                            <select v-model.number="data.framework[3].framework_type">
                                <option value="0">All</option>
                                <option value="1">Own</option>
                                <option value="2">Closed</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field" v-if="data.framework[3].framework_type === 1">
                    <div class="label">App</div>
                    <div class="control">
                        <div class="select">
                            <select v-model="data.framework[3].framework_app" v-on:change="changeIcon(3)">
                                <option v-for="app in data.apps" v-bind:value="app.id">
                                    {{ app.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="button is-primary" :class="savingFrameworkResultClass" v-on:click="saveFramework" :disabled="savingFramework">Save Framework</button>
    </section>
</template>

<script>
export default {
    name: "Framework",
    props: [
        'appId'
    ],
    data() {
        return {
            loading: true,
            appActive: [
                false,
                false,
                false,
                false
            ],
            data: {
                framework: [
                    {
                        icon_url: '/images/icon_placeholder.png',
                        framework_type: '2',
                        framework_app: null,
                    },
                    {
                        icon_url: '/images/icon_placeholder.png',
                        framework_type: '2',
                        framework_app: null,
                    },
                    {
                        icon_url: '/images/icon_placeholder.png',
                        framework_type: '2',
                        framework_app: null,
                    },
                    {
                        icon_url: '/images/icon_placeholder.png',
                        framework_type: '2',
                        framework_app: null,
                    }
                ],
                apps: []
            },
            savingFramework: false,
            savingFrameworkResultClass: '',
        }
    },
    mounted() {
        this.loadFramework();
    },
    methods: {
        changeIcon(position) {
            let appId = this.data.framework[position].framework_app;
            this.data.framework[position].icon_url = this.getIconUrl(appId);
        },
        getIconUrl(appId) {
            for(let i = 0; i < this.data.apps.length; i++) {
                let app = this.data.apps[i];

                if(app.id === appId) {
                    return app.icon_url;
                }
            }

            return '/images/icon_placeholder.png';
        },
        loadFramework() {
            axios.get('/app/' + this.appId + '/framework')
            .then(response => {
                this.data = response.data;

                this.loading = false;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        saveFramework() {
            this.savingFramework = true;

            axios.post('/app/' + this.appId + '/framework', {
                data: this.data.framework,
            })
            .then(response => {
                this.savingFrameworkResultClass = 'is-primary';
            })
            .catch(error => {
                console.log(error.response);
                this.savingFrameworkResultClass = 'is-danger';
            })
            .finally(() => {
                this.savingFramework = false;

                setTimeout(() => {
                    this.savingFrameworkResultClass = '';
                }, 2500);
            });
        }
    }
}
</script>

<style scoped>

</style>
