<template>
<section>
    <h3 class="subtitle">Lock Reasons</h3>

    <b-table
        v-if="data.length > 0"
        :data="data"
        paginated
        per-page="10"
    >
        <b-table-column
            field="text"
            label="Text"
            sortable
            v-slot="props"
        >
            {{ props.row.text }}
        </b-table-column>
    </b-table>

    <input class="input" type="text" v-model="text"></input>

    <button class="button" v-on:click="postLockReason">Save</button>
</section>
</template>

<script>
export default {
    name: "AppList",
    data() {
        return {
            apps: 0,
            data: [],
            text: null,
        }
    },
    mounted() {
        this.getLockReasons();
    },
    methods: {
        postLockReason() {
            axios.post('/admin/options-lock-reasons', {
                text: this.text,
            })
            .then(response => {
                if(response.status === 200) {
                    this.text = null;
                    this.getLockReasons();
                }
            });
        },
        getLockReasons() {
            axios.get('/admin/options-lock-reasons')
            .then(response => {
                this.data = response.data;
            });
        }
    }
}
</script>

<style scoped>

</style>
