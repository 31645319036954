<template>
    <div>
        <!-- Mobile -->
        <div v-if="this.isMobile">
            <VueSlickCarousel v-if="this.carousel === this.carousels[0]" :arrows="false" :dots="true">
                <Startseite1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Startseite1>
                <Startseite2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Startseite2>
                <Startseite3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Startseite3>
            </VueSlickCarousel>
        </div>
        <!-- Web -->
        <div v-else>
            <div v-if="this.carousel === this.carousels[0]" class="columns">
                <div class="column is-4">
                    <Startseite1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Startseite1>
                </div>
                <div class="column is-4">
                    <Startseite2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Startseite2>
                </div>
                <div class="column is-4">
                    <Startseite3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Startseite3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    import VueSlickCarousel from 'vue-slick-carousel';

    import Startseite1 from './startseite-1';
    import Startseite2 from './startseite-2';
    import Startseite3 from './startseite-3';

    export default {
        name: 'Carousel',
        props: [
            'isMobile',
            'carousel',
            'isTablet',
        ],
        data() {
            return {
                carousels: [
                    'Startseite-1',
                ],
            }
        },
        components: {
            VueSlickCarousel,
            Startseite1,
            Startseite2,
            Startseite3
        },
    }
</script>
