<template>
<button
    v-if="buttonAvailable"
    type="button"
    class="button is-primary is-small button-margin-top"
    v-on:click="checkApp"
    :data-tooltip="buttonTooltip"
    :disabled="buttonDisabled"
>
    {{ buttonText }}
</button>
</template>

<script>
export default {
    name: "StoreCheckApp",
    props: [
        'appId'
    ],
    data() {
        return {
            buttonText: null,
            buttonTooltip: null,
            buttonAvailable: false,
            buttonDisabled: true,
            checkPossible: false,
            minutesUntilCheck: 0,
        }
    },
    mounted() {
        this.checkAvailable();

        setInterval(() => {
            this.checkAvailable();
        }, 30000);
    },
    methods: {
        checkAvailable() {
            axios.get('/app/check-store/' + this.appId + '/available')
            .then(response => {
                let data = response.data;
                this.checkPossible = data.check_possible;
                this.minutesUntilCheck = data.minutes_until_check;

                if(!this.checkPossible) {
                    this.buttonDisabled = true;
                    this.buttonText = 'Check Store (' + this.minutesUntilCheck + 'm)';

                    let minutesOrMinute = 'minute';
                    if(this.minutesUntilCheck !== 1) {
                        minutesOrMinute += 's';
                    }

                    this.buttonTooltip = 'You can start the next check in ' + this.minutesUntilCheck + ' ' + minutesOrMinute + '.';
                } else {
                    this.buttonText = 'Check Store';
                    this.buttonTooltip = 'Check if the app is in the Google Play Store.';
                    this.buttonDisabled = false;
                }

                this.buttonAvailable = true;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        checkApp() {
            axios.get('/app/check-store/' + this.appId)
            .then(response => {
                if(response.status === 200) {
                    if(response.data.status === 200) {
                        this.$emit('store-check-ok');
                    } else if(response.data.status === 404) {
                        this.checkAvailable();
                    }
                }
            })
            .catch(error => {
                console.log(error.response);
            });
        }
    }
}
</script>

<style scoped>

</style>
