<template>
    <section>
        <h3 class="subtitle">Icon Review</h3>

        <b-table
            v-if="data.length > 0"
            :data="data"
            paginated
            per-page="15"
        >
            <b-table-column
                field="startdate"
                label="Review Start Time"
                sortable
                v-slot="props"
            >
                {{ props.row.review_started_at }}
            </b-table-column>

            <b-table-column
                field="icon"
                label="Icon"
                sortable
                v-slot="props"
            >
                <img
                    style="width: 100px; height: auto;"
                    :src="props.row.icon_url"
                >
            </b-table-column>

            <b-table-column
                field="action"
                label="Action"
                sortable
                v-slot="props"
            >
                <div class="buttons">
                    <button
                        class="button"
                        type="button"
                        v-on:click="openReviewModal(props.row.id, props.row.icon_url)"
                    >
                        Review
                    </button>
                </div>
            </b-table-column>
        </b-table>

        <div class="modal" :class="{ 'is-active': iconReviewModalActive }">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Icon Review</p>
                    <button class="delete" aria-label="close" v-on:click="closeReviewModal" :disabled="sending"></button>
                </header>
                <section class="modal-card-body">
                    <div>
                        <img :alt="iconReview.icon_url" :src="iconReview.icon_url" />
                    </div>

                    <div class="field">
                        <label class="label">Result</label>
                        <div class="select" :class="{ 'is-danger': errors.review_result.length > 0 }">
                            <select v-model="iconReview.review_result" v-on:change="checkReviewResultValue">
                                <option></option>
                                <option value="0">ok</option>
                                <option value="1">not ok</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="errors.review_result.length > 0" class="notification is-danger">
                        <ul>
                            <li v-for="error in errors.review_result">
                                {{ error }}
                            </li>
                        </ul>
                    </div>

                    <div class="field" v-if="iconReview.review_result === '1'">
                        <label class="label">Reason of Rejection</label>
                        <div class="select" :class="{ 'is-danger': errors.review_not_ok_reason.length > 0 }">
                            <select v-model="iconReview.review_not_ok_reason">
                                <option></option>
                                <option>Copyrighted Material</option>
                                <option>Display of Drug Usage</option>
                                <option>Excessive Violence</option>
                                <option>False Claims</option>
                                <option>Hate Speech</option>
                                <option>Miscellaneous</option>
                                <option>Nudity or Sexual Content</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="errors.review_not_ok_reason.length > 0 && iconReview.review_result === '1'" class="notification is-danger">
                        <ul>
                            <li v-for="error in errors.review_not_ok_reason">
                                {{ error }}
                            </li>
                        </ul>
                    </div>

                    <div class="field">
                        <label class="label">Comment (Optional)</label>
                        <div class="control">
                            <textarea v-model="iconReview.review_comment" class="textarea"></textarea>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" :class="{ 'is-loading': sending }" v-on:click="saveReview" :disabled="sending">Save</button>
                    <button class="button" v-on:click="closeReviewModal" :disabled="sending">Close</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "IconsToReviewList",
    mounted() {
        this.loadIconsToReview();
    },
    data() {
        return {
            data: [],
            iconReview: {
                icon_id: null,
                icon_url: null,
                review_result: null,
                review_not_ok_reason: null,
                review_comment: null,
            },
            sending: false,
            errors: {
                review_result: [],
                review_not_ok_reason: [],
            },
            iconReviewModalActive: false,
        }
    },
    methods: {
        loadIconsToReview() {
            axios.get('/review/icons')
            .then(result => {
                this.data = result.data;
            })
            .catch(error => {
                console.log(error);
            });
        },
        openReviewModal(iconId, iconUrl) {
            this.iconReview.icon_id = iconId;
            this.iconReview.icon_url = iconUrl;
            this.iconReviewModalActive = true;
        },
        closeReviewModal() {
            this.iconReviewModalActive = false;

            this.iconReview = {
                icon_id: null,
                icon_url: null,
                review_result: null,
                review_not_ok_reason: null,
                review_comment: null,
            }
        },
        checkReviewResultValue() {
            if(this.iconReview.review_result === 0) {
                this.iconReview.review_not_ok_reason = null;
            }
        },
        saveReview() {
            this.errors = {
                review_result: [],
                review_not_ok_reason: [],
            };

            this.sending = true;

            axios.post('/review/icon-review', this.iconReview)
            .then(response => {
                if(response.status === 200) {
                    this.closeReviewModal();
                    this.loadIconsToReview();
                }
            })
            .catch(error => {
                console.log(error.response);
                this.errors = error.response.data.errors;

                if(this.errors.review_result === undefined) {
                    this.errors.review_result = [];
                }

                if(this.errors.review_not_ok_reason === undefined) {
                    this.errors.review_not_ok_reason = [];
                }
            })
            .finally(() => {
                this.sending = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
