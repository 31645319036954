<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
                    <WaveBottom2 :is-mobile="this.isMobile"></WaveBottom2>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <h1 class="title has-text-white">
                            Register
                        </h1>
                        <form method="POST" action="/register">
                            <input type="hidden" name="_token" :value="csrf">

                            <div class="is-size-5-custom has-text-left"><strong>E-Mail Address</strong></div>
                            <div class="control has-icons-left">
                                <input
                                    type="email"
                                    class="input form-control"
                                    :class="{ 'is-danger': this.errorEmail === '1' }"
                                    name="email"
                                    :value="this.oldEmail"
                                    required
                                    autocomplete="email"
                                    placeholder="Your E-Mail Address">
                                <span class="icon is-small is-left">
                                    <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'envelope' }"/>
                                </span>

                                <article class="message is-danger auth-margin-error width-100p" v-if="this.errorEmail === '1'">
                                    <div class="message-body has-text-left">
                                        {{ this.errorEmailMessage }}
                                    </div>
                                </article>
                            </div>

                            <br>

                            <div class="is-size-5-custom has-text-left"><strong>Password</strong></div>
                            <div class="control has-icons-left">
                                <input
                                    type="password"
                                    class="input form-control"
                                    :class="{ 'is-danger': this.errorPassword === '1' }"
                                    name="password"
                                    required
                                    autocomplete="new-password"
                                    placeholder="Your Password">
                                <span class="icon is-small is-left">
                                    <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'key' }"/>
                                </span>

                                <article class="message is-danger auth-margin-error width-100p" v-if="this.errorPassword === '1'">
                                    <div class="message-body has-text-left">
                                        {{ this.errorPasswordMessage }}
                                    </div>
                                </article>
                            </div>

                            <br>

                            <div class="control">
                                <div class="field">
                                    <div class="custom-checkbox-wrapper" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': this.isTablet }">
                                        <input
                                            id="registerAgreeMobile"
                                            type="checkbox"
                                            class="is-checkradio custom-checkradio"
                                            name="terms">
                                        <label id="registerAgreeMobileLabel" for="registerAgreeMobile" class="is-size-6 custom-checkbox-color">
                                            I agree to the <a href="/terms" target="_blank">Terms of Use</a> and the <a href="https://kmso.de/datenschutz" target="_bank" >Privacy Policy</a>.
                                        </label>

                                        <article class="message is-danger auth-margin-error width-100p" v-if="this.errorTerms === '1'">
                                            <div class="message-body has-text-left">
                                                {{ this.errorTermsMessage }}
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>

                            <br>

                            <div class="control">
                                <button type="submit" class="is-size-5-custom button is-primary width-100p">
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/images/rocket.svg" alt="Rocket" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="columns is-vertical-center is-centered">
                            <div class="column is-3">
                                <h1 class="title has-text-white">
                                    Register
                                </h1>
                                <form method="POST" action="/register">
                                    <input type="hidden" name="_token" :value="csrf">

                                    <div class="is-size-5-custom has-text-left"><strong>E-Mail Address</strong></div>
                                    <div class="control has-icons-left">
                                        <input
                                            type="email"
                                            class="input form-control"
                                            :class="{ 'is-danger': this.errorEmail === '1' }"
                                            name="email"
                                            :value="this.oldEmail"
                                            required
                                            autocomplete="email"
                                            placeholder="Your E-Mail Address">
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'envelope' }"/>
                                        </span>

                                        <article class="message is-danger auth-margin-error width-100p" v-if="this.errorEmail === '1'">
                                            <div class="message-body has-text-left">
                                                {{ this.errorEmailMessage }}
                                            </div>
                                        </article>
                                    </div>

                                    <br>

                                    <div class="is-size-5-custom has-text-left"><strong>Password</strong></div>
                                    <div class="control has-icons-left">
                                        <input
                                            type="password"
                                            class="input form-control"
                                            :class="{ 'is-danger': this.errorPassword === '1' }"
                                            name="password"
                                            required
                                            autocomplete="new-password"
                                            placeholder="Your Password">
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon width="1em" :icon="{ prefix: 'fa', iconName: 'key' }"/>
                                        </span>

                                        <article class="message is-danger auth-margin-error width-100p" v-if="this.errorPassword === '1'">
                                            <div class="message-body has-text-left">
                                                {{ this.errorPasswordMessage }}
                                            </div>
                                        </article>
                                    </div>

                                    <br>

                                    <div class="control">
                                        <div class="field">
                                            <div class="custom-checkbox-wrapper has-text-justified">
                                                <input
                                                    id="registerAgree"
                                                    type="checkbox"
                                                    class="is-checkradio custom-checkradio"
                                                    name="terms">
                                                <label id="registerAgreeLabel" for="registerAgree" class="is-size-6 custom-checkbox-color">
                                                    I agree to the <a href="/terms" target="_blank">Terms of Use</a> and the <a href="https://kmso.de/datenschutz" target="_bank" >Privacy Policy</a>.
                                                </label>

                                                <article class="message is-danger auth-margin-error width-100p" v-if="this.errorTerms === '1'">
                                                    <div class="message-body has-text-left">
                                                        {{ this.errorTermsMessage }}
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="control">
                                        <button type="submit" class="is-size-5-custom button is-primary width-100p">
                                            Register
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom2 :is-mobile="this.isMobile"></WaveBottom2>
            </section>
        </div>
        <footer-element
            :is-mobile="this.isMobile"
            :is-tablet="this.isTablet">
        </footer-element>
    </section>
</template>

<script>
    import WaveBottom2 from '../Layout/components/wave-bottom-2';
    import Particles from '../Layout/components/particles-stars';

    export default {
        name: "Register",
        props: [
            'isMobile',
            'isTablet',
            'oldEmail',
            'errorEmail',
            'errorEmailMessage',
            'errorPassword',
            'errorPasswordMessage',
            'errorTerms',
            'errorTermsMessage',
        ],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        components: {
            WaveBottom2,
            Particles,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
