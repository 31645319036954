<template>
    <div class="line-height-0">
        <div class="wave-footer-bottom-img foreground" :class="this.backgroundCustom"></div>
    </div>
</template>

<script>
    export default {
        name: 'Wave-Footer-Bottom',
        props: [
            'backgroundCustom',
        ],
    }
</script>
