<template>
<section>
    <h3 class="subtitle">User Info</h3>
    <p>Users on platform: {{ users }}</p>

    <b-table
        v-if="data.length > 0"
        :data="data"
        paginated
        per-page="10"
    >
        <b-table-column
            field="id"
            label="ID"
            sortable
            v-slot="props"
        >
            {{ props.row.id }}
        </b-table-column>

        <b-table-column
            field="name"
            label="Username"
            sortable
            v-slot="props"
        >
            {{ props.row.name }}
        </b-table-column>

        <b-table-column
            field="apps"
            label="Apps"
            sortable
            v-slot="props"
        >
            {{ props.row.apps }}
        </b-table-column>

        <b-table-column
            field="points"
            label="Points"
            sortable
            v-slot="props"
        >
            {{ props.row.points }}
        </b-table-column>

        <b-table-column
            field="registration"
            label="Registration"
            sortable
            v-slot="props"
        >
            {{ props.row.registration }}
        </b-table-column>
    </b-table>
</section>
</template>

<script>
export default {
    name: "UserList",
    data() {
        return {
            users: 0,
            data: [],
        }
    },
    mounted() {
        this.loadUserInfo();
    },
    methods: {
        loadUserInfo() {
            axios.get('/admin/user-info')
            .then(response => {
                this.data = response.data.data;
                this.users = response.data.amount;
            })
            .catch(error => {
                console.log(error.response);
            });
        }
    }
}
</script>

<style scoped>

</style>
