<template>
    <section>
        <h1 class="title">Review</h1>
        <hr>
        <icons-to-review-list></icons-to-review-list>
        <br />
        <apps-to-check></apps-to-check>
    </section>
</template>

<script>
import IconsToReviewList from "./IconsToReviewList";
import AppsToCheck from "./AppsToCheck";

export default {
    components: {
        IconsToReviewList,
        AppsToCheck,
    },
    name: "IndexReview",
    methods: {
    }
}
</script>

<style scoped>

</style>
