<template>
<section>
    <table class="table is-fullwidth">
        <tbody>
        <tr>
            <td>
                <b-tooltip label="If true: this app will not be displayed in framework slots of type 'All'." type="is-info">
                    Hidden
                </b-tooltip>
            </td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="isHidden">
                            <option value="0">False</option>
                            <option value="1">True</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <b-tooltip label="If false: this app can be displayed in other admin account apps with framework slot type 'All' even if 'Hidden' is set to true." type="is-info">
                    Admin Hidden
                </b-tooltip>
            </td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="adminIsHidden">
                            <option value="0">False</option>
                            <option value="1">True</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <b-tooltip label="If true: this app can be chosen from other admin accounts when framework slot type is 'Own'." type="is-info">
                    Admin Framework
                </b-tooltip>
            </td>
            <td>
                <div class="control">
                    <div class="select">
                        <select v-model="adminShowAsTypeOwn">
                            <option value="0">False</option>
                            <option value="1">True</option>
                        </select>
                    </div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="columns">
        <div class="column has-text-centered">
            <button class="button is-primary" type="button" v-on:click="postAdminOptions">Save Changes</button>
        </div>
    </div>
</section>
</template>

<script>

export default {
    components: {
    },
    name: "AppOverview",
    props: [
        'appId',
    ],
    data() {
        return {
            isHidden: false,
            adminIsHidden: false,
            adminShowAsTypeOwn: false,
        }
    },
    mounted() {
        this.getAdminOptions();
    },
    methods: {
        getAdminOptions() {
            axios.get('/app/admin/options/' + this.appId)
            .then(response => {
                this.isHidden = response.data.is_hidden;
                this.adminIsHidden = response.data.admin_is_hidden;
                this.adminShowAsTypeOwn = response.data.admin_show_as_type_own;
            });
        },
        postAdminOptions() {
            axios.post('/app/admin/options/' + this.appId, {
                is_hidden: this.isHidden,
                admin_is_hidden: this.adminIsHidden,
                admin_show_as_type_own: this.adminShowAsTypeOwn,
            });
        }
    }
}
</script>

<style scoped>

</style>
