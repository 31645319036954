<template>
    <section>
        <div class="columns">
            <div class="column is-12">
                <h1 class="title">Admin Panel</h1>

                <div class="tabs">
                    <ul>
                        <li :class="{ 'is-active': tabsActive[0] }" v-on:click="selectTab(0)"><a>Overview</a></li>
                        <li :class="{ 'is-active': tabsActive[1] }" v-on:click="selectTab(1)"><a>Options</a></li>
                    </ul>
                </div>

                <div v-if="tabsActive[0]">
                    <user-list></user-list>
                    <br />
                    <app-list></app-list>
                    <br />
                    <app-review-list></app-review-list>
                </div>

                <div v-if="tabsActive[1]">
                    <options-lock-reasons></options-lock-reasons>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import UserList from "./UserList";
import AppList from "./AppList";
import AppReviewList from "./AppReviewList";
import OptionsLockReasons from "./OptionsLockReasons";

export default {
    components: {
        UserList,
        AppList,
        AppReviewList,
        OptionsLockReasons
    },
    name: "IndexAdmin",
    data() {
        return {
            tabsActive: [
                true,
                false,
            ]
        }
    },
    methods: {
        selectTab(id) {
            this.tabsActive = new Array(2).fill(false);
            this.tabsActive[id] = true;
        }
    }
}
</script>

<style scoped>

</style>
