import Vue from 'vue';
import Buefy from 'buefy';
import VueCookies from 'vue-cookies';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTimes, faCheck, faPen, faTrash, faShareAlt, faCommentAlt, faThumbsUp, faComment, faEnvelope, faPhone, faMapMarkerAlt, faEye, faExclamationTriangle, faKey} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import AOS from 'aos';
import 'bulma-checkradio';

require('./bootstrap');

library.add(faTimes);
library.add(faCheck);
library.add(faPen);
library.add(faTrash);
library.add(faShareAlt);
library.add(faCommentAlt);
library.add(faThumbsUp);
library.add(faComment);
library.add(faEnvelope);
library.add(faPhone);
library.add(faMapMarkerAlt);
library.add(faEye);
library.add(faExclamationTriangle);
library.add(faKey);

let scrollToElement = require('scroll-to-element');

Vue.use(VueCookies);
Vue.use(Buefy);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('verification-notification', require('./components/VerificationNotification.vue').default);
Vue.component('index-portal', require('./components/Portal/IndexPortal.vue').default);
Vue.component('index-app', require('./components/App/IndexApp.vue').default);
Vue.component('index-app-statistics', require('./components/AppStatistics/IndexAppStatistics.vue').default);
Vue.component('index-review', require('./components/Review/IndexReview.vue').default);
Vue.component('index-transactions', require('./components/Transactions/IndexTransactions.vue').default);
Vue.component('index-admin', require('./components/Admin/IndexAdmin.vue').default);
Vue.component('index-statistics', require('./components/Statistics/IndexStatistics.vue').default);

Vue.component('app', require('./components/Layout/App.vue').default);
Vue.component('terms', require('./components/Layout/Terms.vue').default);
Vue.component('navbar', require('./components/Layout/Navbar.vue').default);
Vue.component('footer-element', require('./components/Layout/components/footer-element.vue').default);
Vue.component('carousel', require('./components/Layout/components/carousel.vue').default);

Vue.component('auth-login', require('./components/Auth/Login.vue').default);
Vue.component('auth-register', require('./components/Auth/Register.vue').default);
Vue.component('auth-verify', require('./components/Auth/Verify.vue').default);
Vue.component('auth-passwords-confirm', require('./components/Auth/Passwords/Confirm.vue').default);
Vue.component('auth-passwords-email', require('./components/Auth/Passwords/Email.vue').default);
Vue.component('auth-passwords-reset', require('./components/Auth/Passwords/Reset.vue').default);

const authCookie = Vue.$cookies.get('auth');
if (authCookie !== null) {
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + authCookie;
}

let globalData = new Vue({
    data: {
        $pageFullyLoaded: null
    }
});

Vue.prototype.$featureTransactions = process.env.MIX_BYA_TRANSACTIONS === 'true';

Vue.mixin({
    computed: {
        $pageFullyLoaded: {
            get: function () { return globalData.$data.$pageFullyLoaded },
            set: function (padeLoaded) { globalData.$data.$pageFullyLoaded = padeLoaded; }
        }
    }
});

// This method is important for some mobile or tablet views with navigation bars that may appear
Vue.prototype.$setCalculatedHeights = function setCalculatedHeights() {
    const querySelectorMainHero = document.getElementsByClassName("hero-main-section-1");

    if (querySelectorMainHero.length === 1) {
        const mainHeroHeight = querySelectorMainHero[0].offsetHeight;

        querySelectorMainHero[0].style.minHeight = 'unset';
        querySelectorMainHero[0].style.height = `${mainHeroHeight}px`;
    }

    const querySelectorMainHeroSection1 = document.getElementsByClassName("hero-main-section-2");

    if (querySelectorMainHeroSection1.length === 1) {
        const mainHeroHeightSection1 = querySelectorMainHeroSection1[0].offsetHeight;

        querySelectorMainHeroSection1[0].style.minHeight = 'unset';
        querySelectorMainHeroSection1[0].style.height = `${mainHeroHeightSection1}px`;
    }

    const querySelectorMainHeroSection2 = document.getElementsByClassName("hero-main-section-3");

    if (querySelectorMainHeroSection2.length === 1) {
        const mainHeroHeightSection2 = querySelectorMainHeroSection2[0].offsetHeight;

        querySelectorMainHeroSection2[0].style.minHeight = 'unset';
        querySelectorMainHeroSection2[0].style.height = `${mainHeroHeightSection2}px`;
    }
};

// This method is not unused. It is used on every VueView to set the content to visible and scroll to element
Vue.prototype.$allComponentsLoaded = function allComponentsLoaded(isMobile) {
    globalData.$data.$pageFullyLoaded = true;

    this.$nextTick(() => {
        if (isMobile) {
            Vue.prototype.$setCalculatedHeights();
        }

        let anchor = window.location.hash;
        if (anchor) {
            anchor += "-anchor";
            const querySelectorAnchor = document.getElementById(anchor.substr(1));
            if (querySelectorAnchor) {
                setTimeout(() => {
                    if (querySelectorAnchor) {
                        document.querySelector(anchor).scrollIntoView();
                    }
                }, 100);
            }
        }
        else {
            Vue.prototype.$orientationScroll();
        }
    });
};

// Get scrollTop
Vue.prototype.$getScrollTop = function getScrollTop() {
    let verticalPosition = 0;

    if (pageYOffset) {
        verticalPosition = pageYOffset;
    }
    else if (document.documentElement.clientHeight) {
        verticalPosition = document.documentElement.scrollTop;
    }
    else if (document.body) {
        verticalPosition = document.body.scrollTop;
    }

    verticalPosition = Number(verticalPosition);

    return isNaN(verticalPosition) ? 0 : verticalPosition.toFixed();
};

// Called if orientation changes. It is used on the Navbars Vue Created Methode
Vue.prototype.$windowOrientationEvent = function windowOrientationEvent() {
    const verticalPosition = Vue.prototype.$getScrollTop();
    location.href = window.location.protocol + "\/\/" + window.location.host + window.location.pathname + "?scroll=" + verticalPosition;
};

// Scroll to scroll Position
Vue.prototype.$orientationScroll = function orientationScroll() {
    let scrollValue = Vue.prototype.$getQueryParameter('scroll');
    if (scrollValue) {
        scrollValue = parseInt(scrollValue);
        if (Number.isInteger(scrollValue)) {
            setTimeout(() => {
                window.scroll(0, scrollValue);
            }, 50);
        }
    }
};

// Get query parameter
Vue.prototype.$getQueryParameter = function getQueryParameter(param) {
    const paramFound = RegExp('[?&]' + param + '=([^&]*)').exec(location.search);
    return paramFound && decodeURIComponent(paramFound[1].replace(/\+/g, ' '));
};

Vue.prototype.$moveToAnchor = function moveToAnchor(anchor) {
    if (window.location.pathname === "/") {
        let scrollElement = null;

        window.location.hash = anchor;
        anchor = "#" + anchor + "-anchor";

        const querySelectorAnchor = document.querySelector(anchor);
        if (querySelectorAnchor) {
            scrollElement = anchor;
        }

        if (scrollElement) {
            scrollToElement(scrollElement, {
                offset: 0,
                ease: 'out-cube',
                duration: 500
            });
        }
    } else {
        window.location.href = "/#" + anchor;
    }
};

const app = new Vue({
    el: '#app',
    created () {
        AOS.init({
            distance: 10,
            offset: 0,
            duration: 500,
            once: true,
        });
        globalData.$data.$pageFullyLoaded = null;
    },
});
