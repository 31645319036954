<script>
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
    extends: Line,
    mixins: [ reactiveProp ],
    name: "AppStatisticsLineChart",
    props: [
        'options',
    ],
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
    methods: {
        updateRender() {
            this.renderChart(this.chartData, this.options);
        }
    }
}
</script>

<style scoped>

</style>
