<template>
    <section>
        <input type="hidden" id="copy-to-clipboard" :value="addAppSuccessApiKey"></input>
        <div class="field">
            <label class="label">App Package Name</label>
            <div class="control">
                <input class="input" v-bind:class="{ 'is-danger': appPackageErrors.length > 0 }" type="text" placeholder="Your App Package Name" id="app_package" v-model="appPackage">
            </div>
        </div>

        <div class="notification" v-if="appPackageAddSuccess">
            <div class="block">
                App has been added successfully!
            </div>

            <div class="block">
                Your api key is <div style="display: inline-block; font-family: monospace;">{{ addAppSuccessApiKey }}</div>
                <a v-on:click="copyApiKeyToClipboard" title="copy to clipboard" class="text-blue">
                    <b-icon :icon="iconCopyApiKey" size="is-small"></b-icon>
                </a>
                .
                <br>
                You will need the api key in the <span class="text-primary">AndroidManifest.xml</span> file. Further information can be found <a class="text-blue" @click="$moveToAnchor('how-to-integrate')">here</a>.
            </div>

            <div class="block">
                You are also able to access your api key by clicking the <b-icon icon="cog-outline" size="is-small"></b-icon>
                button in your app list. Next, upload an icon for your app!
            </div>

            <a class="button is-primary" :href="addAppSuccessHref">Upload Icon</a>
        </div>

        <div class="notification is-danger" v-if="appPackageErrors.length > 0">
            <ul>
                <li v-for="error in appPackageErrors">
                    {{ error }}
                </li>
            </ul>
        </div>

        <button type="button" class="button is-primary" v-on:click="addApp">Add App</button>
    </section>
</template>

<script>
export default {
    name: "AddAppComponent",
    data() {
        return {
            appPackage: null,
            appPackageErrors: [],
            appPackageAddSuccess: false,
            addAppSuccessHref: '/portal',
            addAppSuccessApiKey: '',
            iconCopyApiKey: 'content-copy',
        }
    },
    methods: {
        copyApiKeyToClipboard() {
            let copyInput = document.getElementById('copy-to-clipboard');
            copyInput.setAttribute('type', 'text');
            copyInput.select();
            document.execCommand('copy');
            copyInput.setAttribute('type', 'hidden');

            this.iconCopyApiKey = 'check';
        },
        addApp() {
            this.appPackageErrors = [];
            this.appPackageAddSuccess = false;

            axios.post('/app/create', {
                app_package: this.appPackage
            })
            .then(response => {
                if(response.status === 201) {
                    this.iconCopyApiKey = 'content-copy';

                    this.appPackageAddSuccess = true;
                    this.appPackage = null;
                    this.addAppSuccessHref = '/portal/app/' + response.data.id;
                    this.addAppSuccessApiKey = response.data.api_key;

                    this.$emit('reload-app-list');
                }
            })
            .catch(error => {
                let data = error.response.data;

                Object.keys(data.errors).forEach(value => {
                    switch (value) {
                        case 'app_package':
                            this.appPackageErrors = data.errors[value];
                            break;
                    }
                });
            });
        },
    }
}
</script>

<style scoped>

</style>
